export default {
  computed: {
    emptySwatchesClass() {
      return this.hasAnyLocalStorageSwatches
        ? {
            'empty-column-0': !this.localStorageSwatches[0],
            'empty-column-1': !this.localStorageSwatches[1],
            'empty-column-2': !this.localStorageSwatches[2],
            'empty-column-3': !this.localStorageSwatches[3],
            'empty-column-4': !this.localStorageSwatches[4],
          }
        : {};
    },
    hasAnyLocalStorageSwatches() {
      return this.localStorageSwatches?.length > 0;
    },
    pickerBind() {
      return {
        flat: true,
        showSwatches: true,
        swatches: this.swatches,
        class: this.emptySwatchesClass,
      };
    },
    swatches() {
      return [
        [
          ...(this.hasAnyLocalStorageSwatches ? [this.localStorageSwatches[0] || '#FFFFFF'] : []),
          '#F44336',
          '#B71C1C',
          '#E91E63',
          '#FF80AB',
          '#9C27B0',
        ],
        [
          ...(this.hasAnyLocalStorageSwatches ? [this.localStorageSwatches[1] || '#FFFFFE'] : []),
          '#2196F3',
          '#0D47A1',
          '#80D8FF',
          '#00BCD4',
          '#009688',
        ],
        [
          ...(this.hasAnyLocalStorageSwatches ? [this.localStorageSwatches[2] || '#FFFFFD'] : []),
          '#4CAF50',
          '#1B5E20',
          '#C5E1A5',
          '#CDDC39',
          '#9E9D24',
        ],
        [
          ...(this.hasAnyLocalStorageSwatches ? [this.localStorageSwatches[3] || '#FFFFFC'] : []),
          '#FFEB3B',
          '#FFC107',
          '#FFCC80',
          '#FF9800',
          '#795548',
        ],
        [
          ...(this.hasAnyLocalStorageSwatches ? [this.localStorageSwatches[4] || '#FFFFFB'] : []),
          '#9E9E9E',
          '#212121',
          '#616161',
          '#BDBDBD',
          '#EEEEEE',
        ],
      ];
    },
  },
  data: () => ({
    localStorageSwatches: [],
  }),
  methods: {
    addSwatch(value) {
      const localStorageSwatches = JSON.parse(JSON.stringify(this.localStorageSwatches || []));
      if (!localStorageSwatches.includes(value)) {
        localStorageSwatches.unshift(value);
        localStorage.setItem('color-swatches', JSON.stringify(localStorageSwatches.slice(0, 5)));
      }
    },
  },
  watch: {
    isMenuVisible(nV) {
      if (nV) {
        const swatches = JSON.parse(localStorage.getItem('color-swatches') || '[]');
        this.localStorageSwatches = swatches;
      }
    },
  },
};
