import Vue from 'vue';
import { make } from 'vuex-pathify';
import api from '@/services/api';

import { defaultEpsg, defaultFrontendCoordinateSystems } from '@/assets/js/variables';
const state = {
  cursorPositionEpsg: localStorage.getItem('cursorCrs') ? JSON.parse(localStorage.getItem('cursorCrs')) : defaultEpsg,
  center: localStorage.getItem('center') ? JSON.parse(localStorage.getItem('center')) : null, // [715090.42, 245483.11]
  extent: undefined,
  zoom: localStorage.getItem('zoom') ? JSON.parse(localStorage.getItem('zoom')) : null, // 17
  defaultExtent: [714359.9470024359, 244955.4801302308, 715762.905096829, 246086.43614509873],
  scale: undefined,
  identifiedFeatures: [],
  coordinates: [],
  reverseGeocoderCoordinates: [],
  measurementsGeometries: null,
  measurementsIntersect: null,
  coordinatesEpsgs: {},
  dataBaseEpsg: undefined,
  cacheFilterFeatures: {},
  erroredFeatures: {},
  selectedFeatures: {},
  highlitedFeatures: {},
  measuredFeatures: {},
  layersToBeChanged: new Set(),
  isNewCard: false,
  mapPanelDataFetched: false,
  mapRelationDataFetched: false,
  isMapViewChanged: false,
  spatialBookmarks: [],
  generalPlanGmls: [],
};

const mutations = {
  ...make.mutations(state),
  SET_CENTER: (state, value) => {
    state.center = value;
    localStorage.setItem('center', JSON.stringify(value));
  },
  SET_ZOOM: (state, value) => {
    state.zoom = value;
    localStorage.setItem('zoom', JSON.stringify(value));
  },
  SET_CURSOR_POSITION_EPSG: (state, value) => {
    state.cursorPositionEpsg = value;
    localStorage.setItem('cursorCrs', JSON.stringify(value));
  },
  PUSH_CLICKED_COORDINATES: (state, value) => {
    Vue.set(state.coordinates, state.coordinates.length, value);
  },
  DELETE_CLICKED_COORDINATES: (state, value) => {
    Vue.delete(state.coordinates, value);
  },
  SET_COORDINATE_SYSTEMS: (state, cSystems) => {
    Vue.set(state, 'coordinatesEpsgs', {
      // Default frontend coordinate systems
      ...defaultFrontendCoordinateSystems.reduce((total, current) => {
        total[`EPSG:${current.auth_srid}`] = {
          ...current,
          predefined: true,
          id: 'frontend_predefined',
        };
        return total;
      }, {}),

      // Data base coordinate system
      [`EPSG:${state.dataBaseEpsg.auth_srid}`]: { ...state.dataBaseEpsg, predefined: true, id: 'frontend_predefined' },

      // Configured coordinate systems
      ...cSystems.reduce((total, current) => {
        total[`EPSG:${current.auth_srid}`] = {
          ...current,
          predefined:
            current.predefined ||
            [...defaultFrontendCoordinateSystems.map(cS => cS.auth_srid), state.dataBaseEpsg.auth_srid].includes(
              current.auth_srid
            ),
        };
        return total;
      }, {}),
    });
  },
  SET_DATA_BASE_EPSG: (state, value) => {
    state.dataBaseEpsg = {
      auth_srid: value.srid,
      name: value.verbose_name,
      proj4text: value.proj4,
    };
  },
  ADD_SELECTED_FEATURES: (state, value) => {
    const { features, type } = value;
    const changedLayers = Object.keys(features).map(layerId => {
      Vue.set(
        state[type],
        layerId,
        type === 'measuredFeatures'
          ? { ...state[type][layerId], ...features[layerId] }
          : Array.from(new Set([...(state[type][layerId] || []), ...features[layerId]]))
      );
      return layerId;
    });
    state.layersToBeChanged = new Set([...state.layersToBeChanged, ...changedLayers]);
  },
  DELETE_SELECTED_FEATURES: (state, value) => {
    const { features, type } = value;
    const changedLayers = Object.keys(features)
      .filter(layerId => state[type][layerId])
      .map(layerId => {
        if (Object.values(features[layerId]).length === 0) {
          Vue.delete(state[type], layerId);
          if (type === 'highlitedFeatures') {
            Vue.delete(state['measuredFeatures'], layerId);
          }
        } else {
          features[layerId].forEach(feature => {
            Vue.delete(
              state[type][layerId],
              type === 'measuredFeatures' ? feature : state[type][layerId].indexOf(feature)
            );
            if (type === 'highlitedFeatures' && state['measuredFeatures'][layerId]) {
              Vue.delete(state['measuredFeatures'][layerId], feature);
            }
          });
        }
        return layerId;
      });
    state.layersToBeChanged = new Set([...state.layersToBeChanged, ...changedLayers]);
  },
  SET_CACHE_FILTER_FEATURES: (state, data) => {
    const { layerId, ids } = data;
    Vue.set(state.cacheFilterFeatures, layerId, Object.freeze(ids));
  },
};

const actions = {
  async getAvailableSrids() {
    const r = await api.get('general/crs/available');
    return r.data.data;
  },
  async getCoordinateSystems({ commit }) {
    const [r, rr] = await Promise.all([api.get('general/crs'), api.get('/crs')]);
    commit('SET_DATA_BASE_EPSG', rr.data.data);
    commit('SET_COORDINATE_SYSTEMS', r.data.data);
  },
  async addCoordinateSystem({ dispatch }, payload) {
    await api.post('general/crs', payload);
    await dispatch('getCoordinateSystems');
  },
  async deleteCoordinateSystem({ dispatch }, id) {
    await api.delete(`general/crs/${id}`);
    await dispatch('getCoordinateSystems');
  },
  async editCoordinateSystem({ dispatch }, { id, payload } = {}) {
    await api.post(`general/crs/${id}`, payload);
    await dispatch('getCoordinateSystems');
  },
  async mapPrint(ctx, { payload, version = '' }) {
    return (await api.post(`/map_print${version ? `_${version}` : ''}`, payload)).data.data.tid;
  },
  async getCacheLayerIds({ commit }, payload) {
    const { layerId, filters } = payload;
    const r = await api.post(
      `/layers/features_layers/${layerId}/features`,
      {
        features_filter: filters,
      },
      {
        params: {
          ids_only: true,
        },
      }
    );
    commit('SET_CACHE_FILTER_FEATURES', { layerId, ids: r.data.data.ids });
  },
  async getSpatialBookmarks({ commit }) {
    const r = await api.get('/spatial_bookmarks');
    commit('SET_SPATIAL_BOOKMARKS', r.data.data);
  },
  async addSpatialBookmark({ dispatch }, payload) {
    await api.post('/spatial_bookmarks', payload);
    await dispatch('getSpatialBookmarks');
  },
  async deleteSpatialBookmark({ dispatch }, bookmarkId) {
    await api.delete(`/spatial_bookmarks/${bookmarkId}`);
    await dispatch('getSpatialBookmarks');
  },
  async getGeneralPlanGmls({ commit }) {
    const r = await api.get('commune_general_plans');
    commit('SET_GENERAL_PLAN_GMLS', r.data.data);
  },
  async getGeneralPlanGmlFileAsText(store, fileName) {
    const r = await api.get(`commune_general_plans/file/${fileName}`, {
      responseType: 'arraybuffer',
      timeout: 0,
    });
    const textDecoder = new TextDecoder('utf-8');
    const fileText = textDecoder.decode(new Uint8Array(r.data));
    return fileText;
  },
  async deleteGeneralPlanGml({ dispatch }, gmlId) {
    await api.delete(`commune_general_plans/file/${gmlId}`);
    await dispatch('getGeneralPlanGmls');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
