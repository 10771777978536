import { get, sync } from 'vuex-pathify';

import View from 'ol/View';
import { transform } from 'ol/proj';

import { defaultMinZoom } from '@/assets/js/variables';

export default {
  computed: {
    center: sync('map/center'),
    extent: sync('map/extent'),
    zoom: sync('map/zoom'),
    tableHeight: sync('table/tableHeight'),
    isQuickPrintToolActive: sync('tools/toolStatus@isQuickPrintToolActive'),
    currentTab: sync('sidebar/currentTab'),
    isMapViewChanged: sync('map/isMapViewChanged'),
    mapRotationModuleEnabled: get('admin/additionalModules@MAP_ROTATION_MODULE.enabled'),
    isQuickPrintToolLoading: sync('tools/isQuickPrintToolLoading'),
  },
  methods: {
    setMinZoom(zoom = defaultMinZoom) {
      this.map.getView().setMinZoom(zoom);
    },
    prepareView(params = {}) {
      return new View({
        projection: this.$_config.defaultEpsg,
        center: this.center,
        zoom: this.zoom,
        minZoom: defaultMinZoom,
        constrainResolution: true,
        constrainRotation: false,
        extent: this.$_config.defaultEpsgExtent,
        ...params,
      });
    },
    prepareOverviewMapView() {
      return new View({
        projection: this.$_config.defaultEpsg,
        constrainResolution: true,
      });
    },
    setNextView() {
      if (this.isNextViewDisabled) {
        return;
      }
      ++this.currentViewIndex;
      this.changeView(this.currentViewIndex);
    },
    setPreviousView() {
      if (this.isPreviousViewDisabled) {
        return;
      }
      --this.currentViewIndex;
      this.changeView(this.currentViewIndex);
    },
    changeView(index) {
      this.isSaveView = false;
      const { zoom, center } = this.views[index];
      this.setView({ zoom, center });
      this.isMapViewChanged = true;
    },
    setView({ zoom, center, options = { duration: 250 } }) {
      this.map.getView().animate({ zoom, center, ...options });
    },
    setDefaultProjectView() {
      const initialMapViewCenter = transform(
        this.initialMapView.map_center.coordinates,
        this.initialMapView.map_center.crs.properties.name,
        this.$_config.defaultEpsg
      );
      this.setView({ zoom: this.initialMapView.zoom, center: initialMapViewCenter });
    },
    zoomView(isZoomIn) {
      const view = this.map.getView();
      const delta = 1;
      const zoom = isZoomIn ? view.getZoom() + delta : view.getZoom() - delta;
      if (view.getMinZoom() > zoom) {
        this.$store.set('snackbar/PUSH_ERROR!', {
          message: this.$i18n.t('errors.map.maxZoomReached'),
        });
        return;
      }
      this.setView({ zoom });
    },
    quickPrint() {
      /**
       * Browser print freezes app beneath print window,
       * so we have to give some time for snackbar to ensure it can render before print window appears
       */
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isQuickPrintToolLoading)
            this.$store.set('snackbar/PUSH_MESSAGE!', {
              message: this.$i18n.t('snackbar.generatingQuickPrint'),
            });
        }, 250);
      });
      const tableHeight = this.tableHeight;
      this.tableHeight = 0;
      this.isQuickPrintToolActive = this.isQuickPrintToolLoading = true;
      this.map.updateSize();
      this.map.once('rendercomplete', () => {
        document.body.classList.add('one-page-body');
        this.isQuickPrintToolLoading = false;
        this.$nextTick(() => {
          window.print();
        });
      });
      window.onafterprint = () => {
        document.body.classList.remove('one-page-body');
        this.isQuickPrintToolActive = false;
        this.tableHeight = tableHeight;
      };
    },
    rotateMapByDegress(value = 0) {
      if ((value || value === 0) && value >= 0 && value <= 360) {
        this.map?.getView().setRotation((value * Math.PI) / 180);
      } else {
        this.map?.getView().setRotation(0);
      }
    },
  },
};
