import { sync, get } from 'vuex-pathify';
import { prepareDrawnGeometry } from '@/assets/js/mapUtils';

export default {
  computed: {
    additionalAttributesValues: sync('layers/additionalAttributesValues'),
    activeTool: get('tools/activeTool'),
    isActiveToolNotReplaceable: get('tools/isActiveToolNotReplaceable'),
    newFeatureGeometry: sync('edit/newFeatureGeometry'),
    currentTab: sync('sidebar/currentTab'),
  },
  methods: {
    pushToParentRoute(layerId = this.layerId) {
      if (!this.$route.meta.parent === this.$route.name) {
        return;
      }
      this.$router.push({
        name: this.$route.meta.parent,
        params: {
          lid: layerId,
        },
      });
      this.currentTab = 0;
    },
    isButtonDisabled({ skipButtons = [], canBeUsedWithNotReplaceable = false, additionalCondition = false } = {}) {
      const arr = [
        'isAddingButtonActive',
        'isEditingButtonActive',
        'isMoveRotateButtonActive',
        'isCopyButtonActive',
        'isSplittingLineButtonActive',
        'isSplittingPolygonButtonActive',
        'isAssignParcelButtonActive',
        'isAssignMultipleParcelButtonActive',
        'isAttributesPainterButtonActive',
      ];
      const booleanArr = [
        arr.filter(e => !skipButtons.includes(e)).find(e => this[e]) ? true : false,
        !canBeUsedWithNotReplaceable && this.isActiveToolNotReplaceable,
        additionalCondition,
      ];
      return booleanArr.find(e => !!e) ? true : false;
    },
    getButtonTooltip({
      isDisabledByAnotherTool = false,
      condition = true,
      enableTooltip = '',
      disableTooltip = '',
      requirement = '',
    } = {}) {
      if (isDisabledByAnotherTool) {
        return this.$i18n.t('button.oneToolActive');
      }
      return condition
        ? this.$i18n.t(enableTooltip)
        : this.$i18n.t(disableTooltip, { suffix: this.$i18n.t(requirement) });
    },
    setSidebarGeometry(feature) {
      if (!feature) {
        this.newFeatureGeometry = null;
      } else {
        this.newFeatureGeometry = prepareDrawnGeometry(feature);
      }
    },
    async onNewPolygonFeatureGeometry({
      inputName,
      geometry,
      layerId = this.layerId,
      attributesValues,
      area,
      postRouteCallback = () => {},
    }) {
      if (inputName === 'newPolygonFeatureGeometry') {
        this.newFeatureGeometry = geometry;
        await this.$router.push({
          name: 'layerFeature',
          params: {
            ...this.$route.params,
            lid: layerId,
            fid: 'new',
          },
          query: this.$route.query,
        });
        postRouteCallback({ inputName, geometry, attributesValues, area });
      }
    },
  },
  mounted() {
    this.$root.$off('addedSidebarGeometry');
    this.$root.$on('addedSidebarGeometry', this.onNewPolygonFeatureGeometry);
  },
};
