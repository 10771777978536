<template>
  <v-row no-gutters class="pa-0 align-center">
    <v-col cols="auto">
      <the-navbar-tools-panel-map-info-item id="scale" :class="{ 'print-scale': isQuickPrint }" />
    </v-col>
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }">
      <the-navbar-tools-panel-map-info-item
        :text="isEditZoomLevelModuleEnabled ? '' : formattedScale"
        :tooltip="$i18n.t('navbar.numericalScaleTooltip')"
        v-if="isFetched"
      >
        <template #content v-if="isEditZoomLevelModuleEnabled">
          <span class="mr-1">1:{{ scale }}</span> (<data-input
            class="zoom-level-input"
            v-model="zoomLevel"
            :data-type="{ name: 'integer' }"
            :allow-negative="false"
            hide-arrows
            hide-details
            @keyup.enter="changeZoomLevel"
          />)
        </template>
      </the-navbar-tools-panel-map-info-item>
    </v-col>
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }">
      <v-menu ref="menu" max-height="250" offset-y left :close-on-content-click="false" :attach="true">
        <template v-slot:activator="{ on }">
          <v-row
            no-gutters
            class="align-center epsgMenu"
            :tooltip-translation="$i18n.t('tooltips.coordinatesSystem')"
            v-on="on"
            style="cursor: pointer"
          >
            <v-col cols="auto">
              <the-navbar-tools-panel-map-info-item class="pr-0" v-text="cursorPositionCoordinateSystemName" />
            </v-col>
            <v-col cols="auto">
              <v-icon size="20"> mdi-chevron-down </v-icon>
            </v-col>
          </v-row>
        </template>
        <v-card class="pa-3 epsg-menu">
          <v-radio-group v-model="cursorPositionEpsg" column class="ma-0" hide-details>
            <template v-for="epsg in arrayEpsg">
              <v-radio
                on-icon="mdi-check"
                off-icon=""
                :value="epsg.value"
                :key="epsg.value"
                color="font"
                :ripple="false"
              >
                <template #label>
                  <v-subheader class="text-caption pa-0 font--text" style="height: auto">
                    {{ epsg.text }}
                  </v-subheader>
                </template>
              </v-radio>
            </template>
          </v-radio-group>
        </v-card>
      </v-menu>
    </v-col>
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }">
      <the-navbar-tools-panel-map-info-item id="mousePosition" />
    </v-col>
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }" v-if="mapRotationModuleEnabled">
      <the-navbar-tools-panel-map-info-item :text="`${$i18n.t('navbar.mapRotationAngle')}: `">
        <template #content>
          <data-input
            class="ml-1 rotate-map-input"
            v-model="mapRotation"
            :data-type="{ name: 'integer' }"
            :minValue="0"
            :maxValue="360"
            :allow-negative="false"
            hide-arrows
            hide-details
            @keyup.enter="changeMapRotation"
          />
          <span class="mr-1" style="font-size: 15px">°</span>
        </template>
      </the-navbar-tools-panel-map-info-item>
    </v-col>
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }">
      <the-navbar-tools-panel-geolocation />
    </v-col>
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }">
      <the-navbar-tools-panel-map-share-view />
    </v-col>
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }">
      <v-tooltip v-model="show" bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip bottom color="font" v-bind="attrs" v-on="on">
            <template #activator="{ on: onTooltip }">
              <v-btn icon v-on="onTooltip" x-small @click="copyBuildInfo">
                <v-icon :size="16" :color="$_colors.iconIdle"> mdi-help-circle </v-icon>
              </v-btn>
            </template>
            <p class="mb-0">{{ frontTooltip }}</p>
            <p class="mb-0">{{ backTooltip }}</p>
          </v-tooltip>
        </template>
        <span>{{ $i18n.t('tooltips.valueCopied') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { get, sync } from 'vuex-pathify';

import { defaultMinZoom, defaultMaxZoom } from '../assets/js/variables';

import TheNavbarToolsPanelMapInfoItem from '@/components/TheNavbarToolsPanelMapInfoItem';
import TheNavbarToolsPanelMapShareView from '@/components/TheNavbarToolsPanelMapShareView';
import TheNavbarToolsPanelGeolocation from '@/components/TheNavbarToolsPanelGeolocation';
import DataInput from '@/components/DataInput';

export default {
  name: 'TheNavbarToolsPanelMapInfo',
  components: {
    TheNavbarToolsPanelMapInfoItem,
    TheNavbarToolsPanelMapShareView,
    TheNavbarToolsPanelGeolocation,
    DataInput,
  },
  data: () => ({
    mapRotation: 0,
    show: false,
    zoomLevel: null,
  }),
  computed: {
    coordinatesEpsgs: get('map/coordinatesEpsgs'),
    cursorPositionEpsg: sync('map/cursorPositionEpsg'),
    isFetched: get('sidebar/isFetched'),
    frontendVersion: get('admin/frontendVersion'),
    backendVersion: get('admin/backendVersion'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    isEditZoomLevelModuleEnabled: get('admin/additionalModules@EDIT_ZOOM_LEVEL.enabled'),
    mapRotationModuleEnabled: get('admin/additionalModules@MAP_ROTATION_MODULE.enabled'),
    computedVersionString() {
      return `
      ${this.$i18n.t('tooltips.frontVersion')}: ${this.frontendVersion}
      ${this.$i18n.t('tooltips.backVersion')}: ${this.backendVersion}
      `;
    },
    frontTooltip() {
      return `
      ${this.$i18n.t('tooltips.frontVersion')}: ${this.frontendVersion}
      `;
    },
    backTooltip() {
      return `
      ${this.$i18n.t('tooltips.backVersion')}: ${this.backendVersion}
      `;
    },
    arrayEpsg() {
      return Object.keys(this.coordinatesEpsgs).reduce((total, current) => {
        return [
          ...total,
          { text: this.coordinatesEpsgs[current].name, value: current, def: this.coordinatesEpsgs[current].proj4text },
        ];
      }, []);
    },
    cursorPositionCoordinateSystemName() {
      return this.coordinatesEpsgs[this.cursorPositionEpsg]
        ? this.coordinatesEpsgs[this.cursorPositionEpsg].name
        : this.$_config.defaultEpsgName;
    },
    scale: get('map/scale'),
    zoom: sync('map/zoom'),
    formattedScale() {
      return `1:${this.scale} (${this.zoom.toFixed()})`;
    },
  },
  methods: {
    copyBuildInfo() {
      navigator.clipboard.writeText(this.computedVersionString);
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 1500);
    },
    changeZoomLevel() {
      if (!isNaN(this.zoomLevel)) {
        if (this.zoomLevel >= defaultMinZoom && this.zoomLevel <= defaultMaxZoom) {
          this.zoom = this.zoomLevel;
        } else if (this.zoomLevel < defaultMinZoom) {
          this.zoom = defaultMinZoom;
          this.zoomLevel = defaultMinZoom;
        } else if (this.zoomLevel > defaultMaxZoom) {
          this.zoom = defaultMaxZoom;
          this.zoomLevel = defaultMaxZoom;
        }
      } else {
        this.zoomLevel = null;
      }
    },
    changeMapRotation() {
      if (this.mapRotation >= 0 && this.mapRotation <= 360) {
        this.$root.$emit('rotateMap', JSON.parse(JSON.stringify(this.mapRotation)));
      }
    },
  },
  watch: {
    cursorPositionEpsg: {
      async handler(nV) {
        if (!this.coordinatesEpsgs[nV]) this.cursorPositionEpsg = this.$_config.defaultEpsg;
        await this.$nextTick();
        this.$refs.menu.updateDimensions();
      },
      immediate: true,
    },
    zoom: {
      handler(nV) {
        if (!nV && nV !== 0) return;
        this.zoomLevel = nV.toFixed();
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$off('setRotation');
    this.$root.$on('setRotation', rotation => {
      this.mapRotation = rotation;
    });
    // elementy DOM wygenerowane przez OL, więc trzeba nadać atrybuty do wyciągnięcia przez CSS z poziomu JS
    this.$nextTick(() => {
      document.querySelector('.scale')?.setAttribute('tooltip-translation', this.$i18n.t('tooltips.linearScale'));
      document
        .querySelector('.mousePosition')
        ?.setAttribute('tooltip-translation', this.$i18n.t('tooltips.mousePosition'));
    });
    if (this.mapRotationModuleEnabled) {
      const mapRotation = +(localStorage.getItem('rotation') || 0);
      this.mapRotation = mapRotation;
    }
  },
};
</script>

<style lang="scss" scoped>
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scalein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(100%);
  }
}
.epsg-menu ::v-deep .v-input__slot {
  width: auto;
  max-width: 270px;
  padding-right: 0.8rem !important;
}
::v-deep .scale {
  border-bottom: 1px solid black;
  position: relative;
  line-height: normal;
  &:before {
    @include scale-pseudoclass-border(left);
    @include scale-pseudoclass-border(right);
  }
  &:after {
    opacity: 0;
    content: '';
  }
  &:hover::after {
    content: attr(tooltip-translation);
    opacity: 1;
    animation: scalein 200ms cubic-bezier(0.25, 0.8, 0.5, 1) alternate;
    position: absolute;
    left: 0;
    right: 0;
    top: 24px;
    min-width: 100px;
    width: fit-content;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 0.5rem;
    color: white;
    font-size: 14px;
    z-index: 999;
  }
}
::v-deep .mousePosition {
  position: relative;
  &:after {
    opacity: 0;
    content: '';
  }
  &:hover::after {
    content: attr(tooltip-translation);
    opacity: 1;
    animation: scalein 200ms cubic-bezier(0.25, 0.8, 0.5, 1) alternate;
    position: absolute;
    left: 0;
    right: 0;
    top: 24px;
    min-width: 100px;
    width: fit-content;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 0.5rem;
    color: white;
    font-size: 14px;
    z-index: 999;
  }
}
::v-deep .epsgMenu {
  position: relative;
  &:after {
    opacity: 0;
    content: '';
  }
  &:hover::after {
    content: attr(tooltip-translation);
    opacity: 1;
    animation: scalein 200ms cubic-bezier(0.25, 0.8, 0.5, 1) alternate;
    position: absolute;
    left: 0;
    right: 0;
    top: 24px;
    min-width: 100px;
    width: fit-content;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 0.5rem;
    color: white;
    font-size: 14px;
    z-index: 999;
  }
}
::v-deep .v-input__slot {
  font-size: 10px;
  height: 18px;
  min-height: 18px !important;
  padding: 0 2px !important;
}
.zoom-level-input ::v-deep .v-input__control .v-input__slot {
  width: 18px;
}
.rotate-map-input ::v-deep .v-input__control .v-input__slot {
  width: 35px;
}
::v-deep .v-input__control .v-input__slot input {
  text-align: center;
  padding: 0 !important;
}
</style>
