<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters :class="{ 'no-print': isQuickPrint }" v-if="computedLayers.length > 0">
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn
            small
            icon
            @click="changeAllLabelsVisibility"
            :title="
              areAllLabelsVisible
                ? $i18n.t('dialog.style.toggleOffAllLabels')
                : $i18n.t('dialog.style.toggleOnAllLabels')
            "
          >
            <v-icon
              :color="$_colors.iconIdle"
              style="height: 21px; width: 21px"
              v-text="areAllLabelsVisible ? '$labelActive' : '$label'"
            ></v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <project-layers-treeview
        v-model="computedLayers"
        :is-manipulation-disabled="isManipulationDisabled"
        @action="treeviewAction"
        @hasUnsavedChanges="$emit('hasUnsavedChanges', $event)"
      />
      <v-list-item
        class="pa-0 pb-1 pl-6"
        :class="{ 'no-print': isQuickPrint }"
        style="cursor: pointer; min-height: 30px"
        v-if="isBasemapItemVisible"
      >
        <v-list-item-action style="margin: 0 5px 0 0; align-self: start">
          <v-row no-gutters class="align-center">
            <v-col cols="6" style="margin: 0">
              <v-checkbox v-model="isBasemapLayerVisible" @change="$emit('hasUnsavedChanges', true)" color="primary" />
            </v-col>
          </v-row>
        </v-list-item-action>
        <v-list-item-content>
          <v-col class="py-0">
            <v-row style="min-height: 27px">
              <v-list-item-title style="text-align: left" class="text-body-2" v-text="basemapLayer.name" />
            </v-row>
          </v-col>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <main-dialog
      v-if="isLayerDeleteDialogLoaded"
      :is-visible.sync="isLayerDeleteDialogVisible"
      :title="$i18n.t('dialog.deleteLayer')"
      :body="dialogBodyText"
      agree-text="dialog.agreeDelete"
      @agree="deleteLayerFromProject"
    />
    <main-dialog
      v-if="isGroupDeleteDialogLoaded"
      :is-visible.sync="isGroupDeleteDialogVisible"
      :title="$i18n.t('dialog.deleteGroup')"
      :body="
        $i18n.t(
          selectedGroup && selectedGroup.layers.length
            ? 'dialog.confirmGroupDeleteWithLayers'
            : 'dialog.confirmGroupDelete',
          {
            name: selectedGroup ? selectedGroup.name : '',
          }
        )
      "
      agree-text="dialog.agreeDelete"
      ref="deleteGroupDialog"
    >
      <template #buttons>
        <v-spacer></v-spacer>
        <template v-if="selectedGroup && selectedGroup.layers.length">
          <v-col cols="auto">
            <button-main-dialog @click="deleteGroupFromProject(true)" :text="$i18n.t('dialog.agreeYes')" />
          </v-col>
          <v-col cols="auto">
            <button-main-dialog cancel @click="deleteGroupFromProject(false)" :text="$i18n.t('dialog.disagreeNo')" />
          </v-col>
        </template>
        <template v-else>
          <v-col cols="auto">
            <button-main-dialog @click="deleteGroupFromProject(true)" :text="$i18n.t('dialog.agreeDelete')" />
          </v-col>
        </template>
        <v-col cols="auto">
          <button-main-dialog cancel @click="$refs.deleteGroupDialog.onClose()" :text="$i18n.t('dialog.disagree')" />
        </v-col>
      </template>
    </main-dialog>
    <main-input-dialog
      v-if="isChangeGroupNameDialogLoaded"
      :is-visible.sync="isChangeGroupNameDialogVisible"
      :inputs="changeGroupNameInputs"
      :title-translation="$i18n.t('dialog.changeGroupName')"
      agree-text="dialog.agreeSave"
      @agree="changeGroupNameHandler"
      :reset-on-close="false"
      disable-default-form-bottom-padding
    />
    <style-dialog
      v-if="isStyleDialogVisible"
      :layer="currentLayerStyleEdit"
      :attributes="currentLayerStyleEditAttributes"
      :geomType="currentLayerStyleEditGeometryType"
      :is-visible.sync="isStyleDialogVisible"
      @acceptStyle="setLayerStyle"
    />
  </v-row>
</template>

<script>
import { get, call } from 'vuex-pathify';

import fetchAttributesSchemaMixin from '@/mixins/fetchAttributesSchemaMixin';

import ButtonMainDialog from '@/components/ButtonMainDialog';
import ProjectLayersTreeview from '@/components/ProjectLayersTreeview';

export default {
  name: 'LegendLayersList',
  mixins: [fetchAttributesSchemaMixin],
  components: {
    MainDialog: () => import('@/components/MainDialog'),
    MainInputDialog: () => import('@/components/MainInputDialog'),
    StyleDialog: () => import('@/components/StyleDialog'),
    ButtonMainDialog,
    ProjectLayersTreeview,
  },
  model: {
    prop: 'layers',
  },
  props: {
    layers: {
      type: Array,
      default: () => [],
    },
    isBasemapItemVisible: {
      type: Boolean,
      deafult: false,
    },
    isManipulationDisabled: {
      type: Boolean,
      default: false,
    },
    groupsNames: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    currentLayerStyleEdit: {},
    currentLayerStyleEditAttributes: [],
    currentLayerStyleEditGeometryType: '',
    isLayerDeleteDialogVisible: false,
    isLayerDeleteDialogLoaded: false,
    isGroupDeleteDialogVisible: false,
    isGroupDeleteDialogLoaded: false,
    isChangeGroupNameDialogLoaded: false,
    isChangeGroupNameDialogVisible: false,
    layerToDelete: undefined,
    selectedGroup: undefined,
    isStyleDialogVisible: false,
  }),
  computed: {
    project: get('layers/project'),
    layersData: get('layers/layers'),
    layersMetadata: get('layers/metadata'),
    basemapLayer: get('layers/basemapLayer'),
    isBasemapLayerVisible: {
      get() {
        return this.project?.basemap_visible;
      },
      set(nV) {
        this.$store.set('layers/SET_PROJECT_BASEMAP_VISIBLE!', nV);
      },
    },
    isModifySnappingActive: get('edit/isModifySnappingActive'),
    zoom: get('map/zoom'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    layersList() {
      return this.$_getFlatGroupsLayers(this.layers).layers;
    },
    currentLayerId() {
      return this.$route.params.lid;
    },
    dialogBodyText() {
      if (!this.layerToDelete) {
        return;
      }
      const { name } = this.layerToDelete;
      return this.$i18n.t('dialog.confirmLayerDelete', { name });
    },
    computedLayers: {
      get() {
        return this.layers;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    computedCurrentLayersWithProject() {
      return {
        projectId: this.project.id,
        currentLayers: this.computedLayers,
      };
    },
    areAllLabelsVisible() {
      return this.layersList
        .filter(layer => layer.type === 'features_layer' && layer.has_permission)
        .every(layer => layer.labels_visible);
    },
    changeGroupNameInputs() {
      return [
        {
          name: 'name',
          dataType: {
            name: 'text',
          },
          value: this.selectedGroup?.name || '',
          translationPath: 'dialog.groupName',
          rules: [
            v => !!v || v == '0' || 'rules.required',
            v => !this.groupsNames.includes(v) || 'rules.alreadyGroupWithName',
          ],
          cols: '12',
          clearable: true,
        },
      ];
    },
    selectedGroupIndex() {
      const stringifyGroup = JSON.stringify({
        name: this.selectedGroup?.name,
        layers: this.selectedGroup?.layers.map(layer => {
          return { id: layer.layer_id };
        }),
      });
      return this.layers.findIndex(group => {
        const { name, layers } = group;
        return (
          JSON.stringify({
            name,
            layers: (layers || []).map(layer => {
              return { id: layer.layer_id };
            }),
          }) === stringifyGroup
        );
      });
    },
  },
  methods: {
    treeviewAction({ name, value } = {}) {
      this[name](value);
    },
    getLayerFeatures: call('layers/getLayerFeatures'),
    refreshProjectLayer(layerId) {
      this.$root.$emit('refreshMvtSource', layerId);
    },
    showLayerDeleteDialog(layer) {
      this.layerToDelete = layer;
      this.isLayerDeleteDialogLoaded = true;
      this.isLayerDeleteDialogVisible = true;
    },
    showGroupDeleteDialog(group) {
      this.selectedGroup = group;
      this.isGroupDeleteDialogLoaded = true;
      this.isGroupDeleteDialogVisible = true;
    },
    changeLayerVisibility(e) {
      if (e.type === 'group') {
        const group = this.layers[e.itemIdx];
        for (const layer of group.layers.filter(layer => layer.has_permission)) {
          this.$root.$emit('toggleProjectLayerVisible', layer.layer_id, e.value);
        }
      } else {
        if (e.parentItemIdx || e.parentItemIdx === 0) {
          const item = this.layers[e.parentItemIdx].layers[e.itemIdx];
          this.$root.$emit('toggleProjectLayerVisible', item.layer_id, e.value);
        } else {
          const item = this.layers[e.itemIdx];
          this.$root.$emit('toggleProjectLayerVisible', item.layer_id, e.value);
        }
      }
      this.$emit('hasUnsavedChanges', true);
    },
    changeAllLabelsVisibility() {
      const visible = !this.areAllLabelsVisible;
      this.$store.set('layers/SET_ALL_LAYERS_LABELS_VISIBILITY!', visible);
      this.$emit('hasUnsavedChanges', true);
      this.$root.$emit('toggleProjectAllLayersLabels', visible);
    },
    changeLabelsVisibility({ type, item }) {
      if (type === 'group') {
        const layers = item.layers.filter(layer => layer.type === 'features_layer' && layer.has_permission);
        const currentVisible = layers.every(layer => layer.labels_visible);
        for (const layer of layers) {
          this.$store.set('layers/SET_LAYER_LABELS_VISIBILITY!', { layerId: layer.layer_id, visible: !currentVisible });
          this.$root.$emit('toggleProjectLayerLabels', layer.layer_id, !currentVisible);
        }
        this.$emit('hasUnsavedChanges', true);
        return;
      }
      this.$store.set('layers/SET_LAYER_LABELS_VISIBILITY!', { layerId: item.layer_id, visible: !item.labels_visible });
      this.$emit('hasUnsavedChanges', true);
      this.$root.$emit('toggleProjectLayerLabels', item.layer_id, !item.labels_visible);
    },
    changeLineArrowsVisibility({ item }) {
      this.$store.set('layers/SET_LAYER_ARROWS_VISIBILITY!', {
        layerId: item.layer_id,
        visible: item.direction_arrows_visible ? false : true,
      });
      this.$emit('hasUnsavedChanges', true);
      this.$root.$emit(
        'toggleProjectLayerDirectionArrows',
        item.layer_id,
        item.direction_arrows_visible ? false : true
      );
    },
    deleteLayerFromProject() {
      const layerId = this.layerToDelete.layer_id;
      this.$store.set('layers/DELETE_PROJECT_LAYER!', layerId);
      this.$root.$emit('deleteProjectLayer', layerId);
      this.$emit('hasUnsavedChanges', true);
      if (this.currentLayerId == layerId) {
        this.$router.push({ name: 'map' });
      }
      this.isLayerDeleteDialogVisible = false;
    },
    deleteGroupFromProject(deleteLayers) {
      const layers = JSON.parse(JSON.stringify(this.selectedGroup.layers));
      if (this.selectedGroupIndex < 0) {
        this.$refs?.deleteGroupDialog?.onClose();
        return;
      }
      this.$store.set('layers/DELETE_PROJECT_GROUP!', this.selectedGroupIndex);
      for (const layer of layers) {
        const layerId = layer.layer_id;
        this.$root.$emit('deleteProjectLayer', layerId);
      }
      if (!deleteLayers) {
        this.$store.set('layers/ADD_PROJECT_LAYERS!', layers);
        this.$root.$emit('pushProjectLayers', layers);
      }
      this.$emit('hasUnsavedChanges', true);
      this.$refs?.deleteGroupDialog?.onClose();
    },
    updateLayersOrder() {
      this.$emit('hasUnsavedChanges', true);
      this.$root.$emit('setProjectLayersOrder');
    },
    setLayerOpacity({ layerId, value }) {
      this.$store.set('layers/SET_LAYER_OPACITY!', { layerId, opacity: value });
      this.$emit('hasUnsavedChanges', true);
      this.$root.$emit('setProjectLayerOpacity', layerId, value);
    },
    setLayer({ type, item }) {
      if (type === 'group') {
        return;
      }
      const customTableRoutesNamesToggleOn = {
        locationLabReports: 'locationLabReportsLayer',
        locationLabReportsLayer: 'locationLabReportsLayer',
        zdmCustomReports: 'zdmCustomReportsLayer',
        zdmCustomReportsLayer: 'zdmCustomReportsLayer',
        usableArea: 'usableAreaLayer',
        usableAreaLayer: 'usableAreaLayer',
        fireSimulation: 'fireSimulationLayer',
        fireSimulationLayer: 'fireSimulationLayer',
      };
      const customTableRoutesNamesToggleOff = {
        locationLabReports: 'locationLabReports',
        locationLabReportsLayer: 'locationLabReports',
        zdmCustomReports: 'zdmCustomReports',
        zdmCustomReportsLayer: 'zdmCustomReports',
        usableArea: 'usableArea',
        usableAreaLayer: 'usableArea',
        fireSimulation: 'fireSimulation',
        fireSimulationLayer: 'fireSimulation',
      };
      const customTableRoutesNamesKeysOn = Object.keys(customTableRoutesNamesToggleOn);
      const customTableRoutesNamesKeysOff = Object.keys(customTableRoutesNamesToggleOff);
      const layerId = item.layer_id;
      if (this.$route.params.lid == layerId) {
        this.$router.push({
          name: customTableRoutesNamesKeysOff.includes(this.$route.name)
            ? customTableRoutesNamesToggleOff[this.$route.name]
            : 'map',
        });
      } else {
        this.$router.push({
          name: customTableRoutesNamesKeysOn.includes(this.$route.name)
            ? customTableRoutesNamesToggleOn[this.$route.name]
            : 'layerTable',
          params: { lid: layerId },
        });
      }
    },
    setLayerStyle(layer) {
      const isServiceLayer = layer.type === 'service_layer';
      const layerId = layer.layer_id;
      const style = layer.style;
      delete layer.style.symbolizationKind;
      this.$store.set('layers/SET_LAYER_STYLE!', { layerId, style });
      if (isServiceLayer) {
        this.$root.$emit('setProjectServiceLayerStyle', layerId);
      } else {
        this.$root.$emit('setProjectLayerStyle', layerId);
        this.$root.$emit('updateLegendStylesCounts', { layersIds: [layerId] });
        if (+this.$route.params.lid === layerId) this.$root.$emit('updateFeatureCardCharts');
      }
      this.$emit('hasUnsavedChanges', true);
      this.isStyleDialogVisible = false;
    },
    async openLayerStyle(layer) {
      if (layer.type === 'service_layer') {
        this.currentLayerStyleEdit = JSON.parse(JSON.stringify(layer));
      } else {
        const layerData = this.layersData[layer?.id];
        this.currentLayerStyleEditGeometryType = layer.geometry_type;
        this.currentLayerStyleEdit = JSON.parse(JSON.stringify(layer));
        const currentLayerMetadata = this.layersMetadata[layer.data_source_name].attributes_schema.attributes;
        this.currentLayerStyleEditAttributes = this.getAttributes(layerData.form_schema.elements).map(a => {
          const datasourceAttributeMetadata = currentLayerMetadata.find(attr => attr.name === a.attribute);
          const isRelational = Boolean(datasourceAttributeMetadata.relation);
          const dataType = datasourceAttributeMetadata.data_type;
          const isDate = ['datetime', 'date', 'time'].includes(dataType.name);
          return {
            isRelational,
            valueLabel: isRelational ? `mvt_rel_${a.attribute}` : a.attribute,
            valueMvt: isDate ? `mvt_int_${a.attribute}` : a.attribute,
            value: a.attribute,
            text: a.label,
            dataType,
            ...(datasourceAttributeMetadata.dict_colors &&
              datasourceAttributeMetadata.allowed_values && {
                colorsDict: datasourceAttributeMetadata.allowed_values.reduce(
                  (acc, value, index) => ({
                    ...acc,
                    ...(datasourceAttributeMetadata.dict_colors[index] && {
                      [value]: datasourceAttributeMetadata.dict_colors[index],
                    }),
                  }),
                  {}
                ),
              }),
          };
        });
      }
      this.isStyleDialogVisible = true;
    },
    getAttributes(elements) {
      return elements.reduce((total, current) => {
        if (current.element_type === 'group') {
          return [...total, ...this.getAttributes(current.elements)];
        }
        return [...total, current];
      }, []);
    },
    async zoomToLayer({ type, item }) {
      if (type === 'group') {
        return;
      }
      const r = await this.getLayerFeatures({
        layer_id: item.layer_id,
        params: {
          with_features: false,
          with_collection_bbox: true,
          with_count: false,
          with_geometry: false,
        },
      });
      if (r.data.data.bbox) {
        this.$root.$emit('fitView', r.data.data.bbox);
      } else {
        this.$store.set('snackbar/PUSH_MESSAGE!', {
          message: this.$i18n.t('snackbar.zoomToEmptyLayer'),
        });
      }
    },
    showChangeGroupNameDialog(group) {
      this.selectedGroup = group;
      this.isChangeGroupNameDialogLoaded = true;
      this.isChangeGroupNameDialogVisible = true;
    },
    changeGroupNameHandler() {
      const name = JSON.parse(JSON.stringify(this.changeGroupNameInputs.find(input => input.name === 'name').value));
      this.$store.set('layers/CHANGE_GROUP_NAME!', { index: this.selectedGroupIndex, name });
      this.isChangeGroupNameDialogLoaded = false;
      this.isChangeGroupNameDialogVisible = false;
      this.changeGroupNameInputs.find(input => input.name === 'name').value = '';
      this.$emit('hasUnsavedChanges', true);
    },
  },
  watch: {
    computedCurrentLayersWithProject: {
      handler(nV = '', oV = '') {
        if (JSON.stringify(oV.currentLayers) !== JSON.stringify(nV.currentLayers) && oV.projectId === nV.projectId) {
          this.updateLayersOrder();
        }
      },
      deep: true,
    },
    isBasemapLayerVisible: {
      handler(nV) {
        this.$root.$emit('toggleBasemapLayer', nV);
      },
      immediate: true,
    },
  },
};
</script>
