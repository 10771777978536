<template>
  <v-menu
    v-model="isMenuVisible"
    :close-on-content-click="false"
    max-width="290px"
    min-width="290px"
    offset-y
    :disabled="disabled || $attrs.readonly"
  >
    <template #activator="{ on }">
      <data-input-text
        v-bind="{ prependInnerIcon: 'mdi-clock-outline', ...$props, ...$attrs }"
        v-model="formattedTime"
        v-on="{
          ...on,
          ...$_filterObjectKeys($listeners, { notAllowedKeys: ['input', 'change'] }),
          input: setInputValue,
        }"
        @blur="setValue"
        :rules="computedRules"
        class="included data-input-time"
      />
    </template>
    <input-card
      @save="save"
      @cancel="cancel"
      :is-save-disabled="isSaveDisabled"
      v-click-outside="{
        handler: onClickOutside,
        include,
        closeConditional,
      }"
    >
      <v-time-picker
        :use-seconds="useSeconds"
        v-model="time"
        v-bind="timePickerProps"
        :format="timePickerProps && timePickerProps.format ? timePickerProps.format : '24hr'"
        full-width
        scrollable
      ></v-time-picker>
    </input-card>
  </v-menu>
</template>

<script>
import inputCard from '@/mixins/inputCard';

import DataInputText from '@/components/DataInputText';
import InputCard from '@/components/InputCard';

export default {
  name: 'DataInputTime',
  mixins: [inputCard],
  components: {
    DataInputText,
    InputCard,
  },
  props: {
    value: {
      type: [Date, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    /* https://day.js.org/docs/en/display/format#list-of-localized-formats */
    localeTimeFormat: {
      validatior: value => {
        return ['LT', 'LTS'].indexOf(value) !== -1;
      },
      default: 'LTS',
    },
    textFieldProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
      default: () => {},
    },
    timestamp: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isMenuVisible: false,
    activeTab: 0,
    formattedTime: undefined,
  }),
  computed: {
    useSeconds() {
      return this.localeTimeFormat === 'LTS';
    },
    isValidRule() {
      return (
        this.isValid ||
        this.$i18n.t('rules.requiredFormat', {
          format: this.timeFormat,
        })
      );
    },
    isValid() {
      const dayjsDate = this.$dayjs(`24.09.1997, ${this.formattedTime}`, `DD.MM.YYYY, ${this.timeFormat}`, true);
      return dayjsDate.isValid();
    },
    computedRules() {
      return [...(this.$attrs.rules || []), ...(this.formattedTime ? [this.isValidRule] : [])];
    },
    timeFormat() {
      return this.$dayjs.Ls[this.timePickerProps?.format === 'ampm' ? 'en' : import.meta.env.VUE_APP_I18N_LOCALE]
        .formats[this.localeTimeFormat];
    },
    computedFormattedTime() {
      if (!this.value) {
        return undefined;
      }
      const value = this.timestamp ? this.$dayjs.unix(this.value) : this.$dayjs(`1970-01-01 ${this.time}`);
      return value.format(this.timeFormat);
    },
    time: {
      get() {
        if (!this.value) {
          const now = this.$dayjs();
          return `${now.hour()}:${now.minute()}:00`;
        }
        if (this.timestamp) {
          return this.$dayjs.unix(this.value).format(this.localeTimeFormat);
        }
        return this.timePickerProps?.format === 'ampm'
          ? this.$dayjs(this.value, this.timeFormat).format(this.localeTimeFormat)
          : this.value;
      },
      set(nV) {
        if (this.timestamp) {
          this.$emit('input', this.$dayjs(`1970-01-01 ${nV}`, 'YYYY-MM-DD HH:mm:ss').unix());
          return;
        }
        const val =
          this.timePickerProps?.format === 'ampm' ? this.$dayjs(nV, this.localeTimeFormat).format(this.timeFormat) : nV;
        this.$emit('input', val);
      },
    },
  },
  watch: {
    computedFormattedTime: {
      immediate: true,
      handler(nV) {
        this.formattedTime = nV;
      },
    },
  },
  methods: {
    setValue() {
      if (!this.value || this.isValid) {
        this.$emit('input', this.getValue());
      }
    },
    setInputValue(v) {
      if (this.isValid || !v) {
        this.$emit('input', this.getValue());
      }
    },
    getValue() {
      if (!this.formattedTime) {
        return this.timestamp ? 0 : null;
      }
      return this.timestamp
        ? this.$dayjs(`1970-01-01 ${this.formattedTime}`, 'YYYY-MM-DD HH:mm:ss').unix()
        : this.formattedTime;
    },
  },
};
</script>

<style scoped lang="scss">
.v-input {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.v-picker {
  border-radius: 0px;
}
::v-deep .v-input__slot {
  margin-bottom: 3px;
  input {
    padding: 0px;
  }
  label {
    top: 0px;
  }
}
.data-input-time {
  ::v-deep {
    .v-text-field__slot {
      padding-left: 5px;
    }
    .v-input__icon--prepend-inner {
      .v-icon {
        color: map-get($colors, 'italic') !important;
      }
    }
    .v-text-field__slot label {
      padding-left: 3px;
    }
  }
}
</style>
