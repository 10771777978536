import { Feature } from 'ol';
import { Fill, Stroke, Style, Circle } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';

export default {
  methods: {
    clearCustomLayer(id) {
      const layer = this.getLayerById(id);
      if (!layer) return;
      layer.getSource().clear();
    },
    customLayerAddFeatures(features, id) {
      const layer = this.getLayerById(id);
      if (!layer) return;
      layer.getSource().addFeatures(features);
    },
    getCustomVectorLayerInstance(id, layerParams = {}) {
      return new VectorLayer({
        id,
        isSpecial: true,
        zIndex: layerParams.zIndex || 999,
        opacity: layerParams.opacity || 1,
        source: new VectorSource(),
        ...(layerParams.style
          ? {
              style: feature => [
                ...(typeof layerParams.style === 'function'
                  ? [layerParams.style(feature)].flat()
                  : [layerParams.style].flat()),
                ...(layerParams.drawPointMarker ? [this.getMarkerStyle()] : []),
              ],
            }
          : !layerParams.defaultOlStyle
            ? {
                style: new Style({
                  fill: new Fill({
                    color: 'rgba(200, 0, 0, 0.6)',
                  }),
                  stroke: new Stroke({
                    color: 'rgba(255, 0, 0, 1)',
                    width: 2,
                  }),
                  image: new Circle({
                    radius: 5,
                    stroke: new Stroke({
                      color: 'rgba(255, 0, 0, 1)',
                    }),
                    fill: new Fill({
                      color: 'rgba(200, 0, 0, 0.6)',
                    }),
                  }),
                }),
              }
            : {}),
      });
    },
    fitViewToCustomLayer(id) {
      const layer = this.getLayerById(id);
      if (!layer) return;
      this.fitView(layer?.getSource()?.getExtent());
    },
    addCustomGeojsonLayer({ id, geometry, layerParams = {}, fitView = false }) {
      if (!id) throw Error('Layer ID not provided.');
      if (!geometry) {
        this.map.removeLayer(this.getLayerById(id));
        return;
      }
      const geojsonFeatures = new GeoJSON().readFeatures(geometry, {
        featureProjection: geometry.crs?.properties?.name || this.$_config.defaultEpsg || 'EPSG:4326',
        dataProjection: geometry.crs?.properties?.name || this.$_config.defaultEpsg || 'EPSG:4326',
      });
      if (this.getLayerById(id)) {
        this.clearCustomLayer(id);
        this.customLayerAddFeatures(geojsonFeatures, id);
      } else {
        const newLayer = this.getCustomVectorLayerInstance(id, layerParams);
        newLayer.getSource().addFeatures(geojsonFeatures);
        this.map.addLayer(newLayer);
      }
      if (fitView) this.fitViewToCustomLayer(id);
    },
    addCustomLayer(geometry, id, layerParams = {}) {
      let featureFromGeom;
      if (Array.isArray(geometry) && geometry?.[0] instanceof Feature) featureFromGeom = geometry;
      else featureFromGeom = geometry ? [new Feature({ geometry })] : [];
      if (this.getLayerById(id)) {
        this.clearCustomLayer(id);
        this.customLayerAddFeatures(featureFromGeom, id);
      } else {
        const newLayer = this.getCustomVectorLayerInstance(id, layerParams);
        newLayer.getSource().addFeatures(featureFromGeom);
        this.map.addLayer(newLayer);
      }
    },
    toggleCustomVectorLayerVisibility(id, value) {
      this.getLayerById(id)?.setVisible(value);
    },
  },
  mounted() {
    this.$root.$off('toggleCustomVectorLayer');
    this.$root.$on('toggleCustomVectorLayer', this.addCustomGeojsonLayer);
    this.$root.$off('toggleCustomVectorLayerVisibility');
    this.$root.$on('toggleCustomVectorLayerVisibility', this.toggleCustomVectorLayerVisibility);
  },
};
