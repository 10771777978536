<template>
  <span>
    <slot
      :item="multiple ? assignMultipleParcelButton : assignParcelButton"
      :default-action="() => assignParcelAction($listeners.customAction)"
    />
  </span>
</template>

<script>
import tableButtonsEditUtils from '@/mixins/tableButtonsEditUtils';

import assignParcel from '@/mixins/assignParcel';

export default {
  name: 'AssignParcelTrigger',
  mixins: [tableButtonsEditUtils, assignParcel],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    layer: {
      type: [Number, String],
    },
    parcelTargetDatasource: {
      type: String,
      required: true,
    },
    forcedProvider: {
      type: String,
      default: null,
    },
    geometryType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    assignParcelButton() {
      return {
        name: 'assignParcel',
        icon: 'mdi-crosshairs-gps',
        disabled:
          (this.isAssignParcelButtonActive && !this.isActive) ||
          this.isButtonDisabled({
            skipButtons: ['isAssignParcelButtonActive'],
            additionalCondition:
              this.activeTool !== 'moduleIdentification' &&
              this.activeTool !== 'identification-assignPlot' &&
              this.isActiveToolNotReplaceable,
          }),
        tooltip: this.getButtonTooltip({
          isDisabledByAnotherTool: this.isButtonDisabled({
            skipButtons: ['isAssignParcelButtonActive'],
            additionalCondition:
              this.activeTool !== 'moduleIdentification' &&
              this.activeTool !== 'identification-assignPlot' &&
              this.isActiveToolNotReplaceable,
          }),
          enableTooltip: 'button.assignParcelGeometry',
          disableTooltip: 'button.toChangeGeometry',
          requirement: 'button.justOneObject',
        }),
        loading: this.uldkAttributesMappingsFetchStatus === 1,
        fontSize: '19px',
        hasState: true,
        isActive: this.isActive && this.isAssignParcelButtonActive,
      };
    },
    assignMultipleParcelButton() {
      return {
        name: 'assignMultipleParcel',
        icon: '$multiparcel',
        disabled: this.isButtonDisabled({
          skipButtons: ['isAssignMultipleParcelButtonActive'],
          additionalCondition:
            this.activeTool !== 'moduleIdentification' &&
            this.activeTool !== 'identification-assignMultiplePlots' &&
            this.isActiveToolNotReplaceable,
        }),
        tooltip: this.getButtonTooltip({
          isDisabledByAnotherTool: this.isButtonDisabled({
            skipButtons: ['isAssignMultipleParcelButtonActive'],
            additionalCondition:
              this.activeTool !== 'moduleIdentification' &&
              this.activeTool !== 'identification-assignMultiplePlots' &&
              this.isActiveToolNotReplaceable,
          }),
          enableTooltip: 'button.assignMultipleParcelsGeometry',
          disableTooltip: 'button.toChangeGeometry',
          requirement: 'button.justOneObject',
        }),
        isLoading: this.isAssigningMultipleParcelsLoading,
        fontSize: '23px',
        hasState: true,
        isActive: this.isActive && this.isAssignMultipleParcelButtonActive,
      };
    },
  },
  mounted() {
    this.fetchUldkMapping();
  },
};
</script>
