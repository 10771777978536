<template>
  <v-row no-gutters class="pt-5 pr-2">
    <v-col>
      <card-title-row
        style="font-size: 18px !important; gap: 2rem"
        translation-path="sidebar.project"
        :amount="1"
        :is-plural="true"
        class="mb-4"
        :class="{ 'no-print': isQuickPrint }"
      >
        <template #postTitle>
          <v-subheader
            class="pa-0"
            style="font-style: italic; font-size: 13px; opacity: 0.8; height: auto"
            v-text="isUnsavedChangeTextVisible"
          ></v-subheader>
        </template>
        <template #buttons>
          <base-rounded-button
            icon="mdi-content-save"
            size="x-small"
            @click="isSaveProjectDisabled ? openCreateProjectDialog() : saveProjectEditing()"
            :tooltip="saveProjectTooltip"
            :disabled="isSaveButtonDisabled"
            :loading="projectEditingLoading"
            loader-color="#fff"
          />
        </template>
      </card-title-row>
      <sidebar-row class="pt-2" :class="{ 'no-print': isQuickPrint }">
        <v-row no-gutters class="pr-1 pb-6 border">
          <v-col cols="auto" style="width: calc(100% - 75px)">
            <data-input
              :tooltip="changeProjectTooltip"
              disabled-sorting
              id="project-data-input"
              :menu-props="{
                contentClass: 'group-input__menu project-data-input',
              }"
              ref="select"
              @click.prevent="isDisplayWarning ? showWarning() : null"
              @change="projectChanged({ e: $event })"
              @blur="!projectProxy ? (projectProxy = JSON.parse(JSON.stringify(project))) : null"
              v-model="projectProxy"
              :items="projectsArray"
              :filter="projectsCustomFilter"
              return-object
              item-text="name"
              item-value="id"
              :no-data-text="$i18n.tc('sidebar.project', 0)"
              :data-type="dataTypeSelect"
              :disabled="isModifySnappingActive"
              hide-details
            >
              <template #append-item>
                <v-tooltip bottom color="font" :disabled="!createProjectTooltip">
                  <template #activator="{ on }">
                    <div v-on="on" class="add-project-wrapper elevation-4">
                      <v-divider style="width: 100%" />
                      <v-list-item
                        :disabled="createProjectDisabled"
                        @click="showCreateProjectDialog"
                        class="py-1"
                        :class="{ 'v-list-item--disabled': createProjectDisabled }"
                      >
                        <v-list-item-title
                          class="py-0 px-1 d-flex flex-row align-center mt-3 mb-1"
                          style="align-self: self-start"
                        >
                          <div style="width: 16px; min-width: 16px" class="mx-2 d-flex justify-center align-center">
                            <v-icon
                              size="16px"
                              :color="createProjectDisabled ? undefined : 'success'"
                              :class="{ 'v-list-item--disabled': createProjectDisabled }"
                            >
                              mdi-plus-circle
                            </v-icon>
                          </div>
                          <span
                            class="text-truncate py-0 mr-4"
                            :style="{ color: createProjectDisabled ? undefined : $_colors.success }"
                            >{{ $i18n.t('button.createProject') }}</span
                          >
                        </v-list-item-title>
                      </v-list-item>
                    </div>
                  </template>
                  {{ createProjectTooltip }}
                </v-tooltip>
              </template>
              <template #prepend-inner>
                <v-icon class="mr-1" size="18px">{{ projectsDict[currentProjectType].icon }}</v-icon>
              </template>
              <template #item="{ item }">
                <v-list-item-content style="flex-direction: column" class="pa-0 my-2">
                  <v-divider v-if="item.isProjectHeader" style="width: 100%"></v-divider>
                  <v-list-item-title
                    :class="[item.isProjectHeader && 'mt-3 mb-1']"
                    class="py-0 px-1 d-flex flex-row align-center"
                    style="align-self: self-start"
                  >
                    <div style="width: 16px; min-width: 16px" class="mx-2 d-flex justify-center align-center">
                      <v-icon v-if="item.prependIcon" size="16px">{{ item.prependIcon }}</v-icon>
                    </div>
                    <v-subheader
                      v-if="item.isProjectHeader"
                      class="text-truncate py-0 mr-4"
                      style="height: auto; width: 100%"
                      >{{ item.headerName }}</v-subheader
                    >
                    <div
                      v-else
                      class="mr-4 d-flex align-center"
                      style="padding-right: 16px; width: 100%; min-width: 0; gap: 0.5rem"
                    >
                      <span class="text-truncate py-0">{{ item.name }}</span>
                      <span v-if="item.label" class="text-caption font-italic font-weight-light"
                        >({{ item.label }})</span
                      >
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </data-input>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="pt-2">
            <dots-menu
              v-if="isProjectLayersFetched"
              :items="dotsMenuItems"
              @delete="showProjectActionDialog('delete')"
              @markAsDefault="showProjectActionDialog('setDefault')"
              @markAsPredefined="showProjectActionDialog('setPredefined')"
              @unmarkAsPredefined="showProjectActionDialog('unsetPredefined')"
              @changeName="changeProjectName"
              @share="showShareDialog"
              @copy="showCreateProjectDialog($event, true)"
            ></dots-menu>
          </v-col>
        </v-row>
      </sidebar-row>
      <template v-if="isProjectLayersFetched">
        <card-title-row
          class="pt-6 pb-1"
          :class="{ 'no-print': isQuickPrint }"
          translation-path="sidebar.layers"
          :is-subtitle="true"
          :amount="2"
          :is-plural="true"
        >
          <template #buttons>
            <base-rounded-button
              :disabled="isAddLayerDisabled"
              :tooltip="addGroupTooltip"
              icon="mdi-folder-multiple-plus"
              size="x-small"
              @click="showAddGroupDialog"
            />
            <base-rounded-button
              :disabled="isAddLayerDisabled"
              :tooltip="addLayerTooltip"
              icon="mdi-plus"
              size="x-small"
              color="success"
              @click="showAddLayersDialog"
            />
          </template>
        </card-title-row>
        <sidebar-row :has-icon-column="false" slotCols="12">
          <v-row no-gutters>
            <div v-if="layers && layers.find(layer => layer.layers && layer.layers.length > 0)" style="width: 2%"></div>
            <div
              :style="`width: ${layers && layers.find(layer => layer.layers && layer.layers.length > 0) ? '98' : '100'}%`"
            >
              <legend-layers-list
                :class="[
                  {
                    'pl-1': layers && layers.find(layer => layer.layers && layer.layers.length > 0) ? false : true,
                  },
                  { 'print-legend_layers': isQuickPrint },
                ]"
                key="project"
                @hasUnsavedChanges="hasUnsavedChanges = $event"
                v-model="layers"
                :is-basemap-item-visible="true"
                :is-manipulation-disabled="isAddLayerDisabled"
                :groups-names="groupsNames"
              ></legend-layers-list>
            </div>
          </v-row>
        </sidebar-row>
      </template>
      <main-loader v-else class="pt-10" />
    </v-col>
    <main-large-dialog
      v-if="isChangeNameDialogLoaded"
      :is-visible.sync="isChangeNameDialogVisible"
      :title="$i18n.t('dialog.changeProjectName')"
      agree-text="dialog.agreeSave"
      disagree-text="dialog.close"
      @agree="setProjectName"
      :is-input-dialog="true"
      header-shadow
    >
      <template #body>
        <v-row no-gutters align="baseline">
          <v-col cols="2"> {{ $i18n.t('dialog.name') }}: </v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" class="mr-2">
            <data-input
              class="new__project__name"
              v-model="projectName"
              clearable
              :data-type="dataTypeText"
            ></data-input>
          </v-col>
        </v-row>
      </template>
    </main-large-dialog>
    <main-large-dialog
      v-if="isCreateProjectDialogLoaded"
      :is-visible.sync="isCreateProjectDialogVisible"
      :title="$i18n.t(`dialog.${isCreateProjectDialogCopy ? 'copyProject' : 'createProject'}`)"
      :agree-text="`dialog.${isCreateProjectDialogCopy ? 'agreeCopy' : 'create'}`"
      @agree="createProjectHandler"
      @modalClosed="isCreateProjectDialogCopy = false"
      :is-input-dialog="true"
      header-shadow
      :loading="isLoading"
    >
      <template #body>
        <v-row no-gutters align="baseline">
          <v-col cols="2"> {{ $i18n.t('dialog.name') }}: </v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" class="mr-2">
            <data-input
              class="new__project__name"
              v-model="projectName"
              :hint="$i18n.t('dialog.newProjectFieldHint')"
              persistent-hint
              clearable
              :data-type="dataTypeText"
            ></data-input>
          </v-col>
        </v-row>
      </template>
    </main-large-dialog>
    <main-dialog
      v-if="isWarningDialogLoaded"
      :is-visible.sync="isWarningDialogVisible"
      :title="$i18n.t('dialog.unsavedChanges')"
      :body="$i18n.t('dialog.unsavedProjectChanges')"
    >
      <template #buttons>
        <v-spacer></v-spacer>
        <button-main-dialog cancel @click="isWarningDialogVisible = false" :text="$i18n.t('dialog.disagree')" />
        <button-main-dialog @click="onWarningDiscard" :text="$i18n.t('dialog.discard')" color="error" />
        <button-main-dialog @click="onWarningAgree" :text="$i18n.t('dialog.agreeSave')" />
      </template>
    </main-dialog>
    <main-dialog
      v-if="isProjectActionDialogLoaded"
      :is-visible.sync="isProjectActionDialogVisible"
      :title="projectActionsTranslations[currentProjectAction].title"
      :body="projectActionsTranslations[currentProjectAction].body"
      :agree-text="projectActionsTranslations[currentProjectAction].agreePath"
      @agree="actionProjectHandler"
      :loading="isLoading"
    ></main-dialog>
    <main-large-dialog
      v-if="isShareDialogLoaded"
      :is-visible.sync="isShareDialogVisible"
      :title="$i18n.t('dialog.shareProject')"
      agree-text="dialog.agreeShare"
      disagree-text="dialog.close"
      @agree="shareProjectHandler"
      :is-input-dialog="true"
      header-shadow
      :loading="isLoading"
    >
      <template #body>
        <v-row no-gutters align="start">
          <v-col cols="2" class="pt-3"> {{ $i18n.t('dialog.user') }}: </v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" class="mr-2 mb-5">
            <data-input
              multiple
              v-model="emailsShare"
              :items="flteredUsers"
              item-text="name"
              item-value="id"
              :hint="$i18n.t('dialog.chooseUserToShareProject')"
              persistent-hint
              :data-type="dataTypeSelect"
              :autoConvertSelect="false"
            >
            </data-input>
          </v-col>
        </v-row>
      </template>
    </main-large-dialog>
    <layers-adding
      :is-layers-adding-visible.sync="isAddLayersDialogVisible"
      :layers="projectLayersList"
      @addLayers="addLayersHandler"
      :loading="isAddingLayerLoading"
    />
    <main-input-dialog
      v-if="isAddGroupDialogLoaded"
      :is-visible.sync="isAddGroupDialogVisible"
      :title-translation="$i18n.t('dialog.addGroup')"
      agree-text="dialog.agreeAdd"
      @agree="addGroupHandler"
      :inputs="addGroupInputs"
    />
    <main-large-dialog
      v-if="isCreateProjectFromDefaultDialogLoaded"
      :is-visible.sync="isCreateProjectFromDefaultDialogVisible"
      :title="$i18n.t('dialog.createProject')"
      agree-text="dialog.create"
      @agree="createProjectFromDefaultHandler"
      :is-input-dialog="true"
      :loading="isLoading"
    >
      <template #body>
        <v-subheader class="text-left px-0">
          {{ createDialogBody }}
        </v-subheader>
        <data-input
          v-model="projectName"
          :label="$i18n.t('dialog.projectName')"
          clearable
          :data-type="dataTypeText"
        ></data-input>
      </template>
    </main-large-dialog>
  </v-row>
</template>

<script>
import { get, sync, call } from 'vuex-pathify';

import CardTitleRow from '@/components/CardTitleRow';
import DotsMenu from '@/components/DotsMenu';
import LegendLayersList from '@/components/LegendLayersList';
import MainLoader from '@/components/MainLoader';
import DataInput from '@/components/DataInput';
import ButtonMainDialog from './ButtonMainDialog';
import SidebarRow from '@/components/SidebarRow';
import LayersAdding from '@/components/LayersAdding';

export default {
  name: 'TheLegend',
  components: {
    SidebarRow,
    CardTitleRow,
    DataInput,
    DotsMenu,
    LegendLayersList,
    MainLoader,
    ButtonMainDialog,
    MainDialog: () => import('@/components/MainDialog'),
    LayersAdding,
    MainLargeDialog: () => import('@/components/MainLargeDialog'),
    MainInputDialog: () => import('@/components/MainInputDialog'),
  },
  data: () => ({
    groupUniqueId: 0,
    dataTypeText: {
      name: 'text',
    },
    isAddingLayerLoading: false,
    projectEditingLoading: false,
    hasUnsavedChanges: false,
    projectName: '',
    projectProxy: undefined,
    wasWarningDisplayed: false,
    emailsShare: '',
    dataTypeSelect: {
      name: 'select',
    },
    isChangeNameDialogVisible: false,
    isChangeNameDialogLoaded: false,
    isWarningDialogVisible: false,
    isWarningDialogLoaded: false,
    isProjectActionDialogVisible: false,
    isProjectActionDialogLoaded: false,
    currentProjectAction: undefined,
    isCreateProjectDialogCopy: false,
    isCreateProjectDialogVisible: false,
    isCreateProjectDialogLoaded: false,
    isShareDialogVisible: false,
    isShareDialogLoaded: false,
    isAddLayersDialogVisible: false,
    isAddGroupDialogVisible: false,
    isAddGroupDialogLoaded: false,
    isCreateProjectFromDefaultDialogVisible: false,
    isCreateProjectFromDefaultDialogLoaded: false,
    isLoading: false,
  }),
  watch: {
    project: {
      immediate: true,
      handler(nV) {
        this.projectProxy = JSON.parse(JSON.stringify(nV));
      },
    },
  },
  computed: {
    mapRotationModuleEnabled: get('admin/additionalModules@MAP_ROTATION_MODULE.enabled'),
    project: sync('layers/project'),
    currentProject: get('layers/projects@:currentProjectId'),
    currentProjectId: get('layers/project.id'),
    isModifySnappingActive: get('edit/isModifySnappingActive'),
    unchangedProject: get('layers/unchangedProject'),
    projects: sync('layers/projects'),
    layers: sync('layers/project@layers'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    isMapViewChanged: sync('map/isMapViewChanged'),
    isProjectLayersFetched: sync('sidebar/isProjectLayersFetched'),
    users: get('users/users'),
    currentUser: get('users/currentUser'),
    currentUserId: get('users/currentUser@id'),
    isUnsavedChangeTextVisible() {
      return (this.isMapViewChanged || this.hasUnsavedChanges) && (!this.isProjectDefault || !this.isSharedProject)
        ? this.$i18n.t('sidebar.unsavedChanges')
        : '';
    },
    isSaveButtonDisabled() {
      if (this.isProjectDefault || this.isProjectPredefined) {
        return (
          !this.isProjectLayersFetched ||
          (!this.isMapViewChanged && !this.hasUnsavedChanges && !!this.isAllowedToManageProjects)
        );
      } else {
        return (
          !this.isProjectLayersFetched || (!this.isMapViewChanged && !this.hasUnsavedChanges && !this.isSharedProject)
        );
      }
    },
    projectLayersList() {
      return this.$_getFlatGroupsLayers(this.layers || []).layers;
    },
    groups() {
      return this.$_getFlatGroupsLayers(this.layers).groups;
    },
    groupsNames() {
      return this.groups.map(group => group.name);
    },
    addGroupInputs() {
      return [
        {
          name: 'name',
          value: '',
          translationPath: 'dialog.groupName',
          cols: '12',
          rules: [
            v => !!v || v == '0' || 'rules.required',
            v => !this.groupsNames.includes(v) || 'rules.alreadyGroupWithName',
          ],
          dataType: {
            name: 'text',
          },
        },
      ];
    },
    changeProjectTooltip() {
      return this.isModifySnappingActive
        ? this.$i18n.t('button.toChangeProject', { reason: this.$i18n.t('button.turnOffEditing') })
        : this.currentProject?.name.length > 30
          ? this.currentProject.name
          : '';
    },
    createProjectDisabled() {
      return !this.isProjectLayersFetched || this.isModifySnappingActive;
    },
    createProjectTooltip() {
      return this.isModifySnappingActive
        ? this.$i18n.t('button.toCreateProject', { reason: this.$i18n.t('button.turnOffEditing') })
        : null;
    },
    usersArray() {
      return this.$_objectToArray(this.users);
    },
    flteredUsers() {
      return this.usersArray.filter(user => user.id !== this.currentUserId);
    },
    isAllowedToManageProjects() {
      return this.currentUser.is_superadmin || this.currentUser.is_admin;
    },
    dotsMenuItems() {
      return [
        ...(this.isUserProject || (!this.isSharedProject && this.isAllowedToManageProjects)
          ? [
              {
                name: 'changeName',
              },
            ]
          : []),
        ...(this.isUserProject
          ? [
              {
                name: 'share',
              },
            ]
          : []),
        {
          name: 'copy',
        },
        ...(this.isAllowedToManageProjects && !this.isProjectDefault && !this.isSharedProject
          ? [
              {
                name: 'markAsDefault',
              },
              ...(this.isProjectPredefined
                ? [
                    {
                      name: 'unmarkAsPredefined',
                    },
                  ]
                : [
                    {
                      name: 'markAsPredefined',
                    },
                  ]),
            ]
          : []),
        ...(this.isUserProject || (this.isProjectPredefined && this.isAllowedToManageProjects)
          ? [
              {
                name: 'delete',
              },
            ]
          : []),
      ];
    },
    projectsDict() {
      return {
        default: {
          label: this.$i18n.t('navbar.defaultProjectIndicator'),
          icon: 'mdi-home',
        },
        predefined: {
          name: this.$i18n.t('navbar.projects.predefined'),
          icon: 'mdi-star',
        },
        owner: {
          name: this.$i18n.t('navbar.projects.owner'),
          icon: 'mdi-account',
        },
        shared: {
          name: this.$i18n.t('navbar.projects.shared'),
          icon: 'mdi-account-group',
        },
      };
    },
    projectActionsTranslations() {
      return {
        delete: {
          title: this.$i18n.t('dialog.deleteProject'),
          body: this.$i18n.t('dialog.confirmProjectDelete'),
          successMessagePath: 'success.successProjectDelete',
          agreePath: 'dialog.agreeDelete',
        },
        setPredefined: {
          title: this.$i18n.t('dialog.setPredefinedProject'),
          body: this.$i18n.t('dialog.confirmSetPredefinedProject', { name: this.currentProject?.name }),
          successMessagePath: 'success.successProjectSetPredefined',
          agreePath: 'dialog.agreeMark',
        },
        unsetPredefined: {
          title: this.$i18n.t('dialog.unsetPredefinedProject'),
          body: this.$i18n.t('dialog.confirmUnsetPredefinedProject', { name: this.currentProject?.name }),
          successMessagePath: 'success.successProjectUnsetPredefined',
          agreePath: 'dialog.agreeUnmark',
        },
        setDefault: {
          title: this.$i18n.t('dialog.setDefaultProject'),
          body: this.$i18n.t('dialog.confirmSetDefaultProject', { name: this.currentProject?.name }),
          successMessagePath: 'success.successProjectSetDefault',
          agreePath: 'dialog.agreeMark',
        },
      };
    },
    projectsArray() {
      const reducedProjects = [...this.$_objectToArray(this.projects)].reduce((acc, item) => {
        const isOwner = this.currentUserId === item.owner;
        const attr = item['role'] ? item['role'] : isOwner ? 'owner' : 'shared';
        if (attr === 'default') {
          item.label = this.projectsDict[attr].label;
          item.prependIcon = this.projectsDict[attr].icon;
        }
        if (!acc[attr]) {
          acc[attr] = [];
        }
        acc[attr].push(item);
        return acc;
      }, {});
      const projectsArray = [];
      Object.entries(this.projectsDict).forEach(([key, value]) => {
        if (reducedProjects[key]) {
          if (key !== 'default')
            projectsArray.push({
              headerName: value.name,
              prependIcon: value.icon,
              disabled: true,
              isProjectHeader: true,
              values: reducedProjects[key],
            });
          projectsArray.push(...this.$_sortObjectsByAttribute(reducedProjects[key], 'name'));
        }
      });
      return projectsArray;
    },
    isDisplayWarning() {
      return this.hasUnsavedChanges && !this.wasWarningDisplayed;
    },
    isProjectDefault() {
      return this.project && this.project.role === 'default';
    },
    isProjectPredefined() {
      return this.project && this.project.role === 'predefined';
    },
    isSharedProject() {
      return !this.isProjectDefault && !this.isProjectPredefined && this.currentUserId !== this.project?.owner;
    },
    isUserProject() {
      return !this.isProjectDefault && !this.isProjectPredefined && this.currentUserId === this.project?.owner;
    },
    isAddLayerDisabled() {
      return (
        this.isSharedProject ||
        ((this.isProjectDefault || this.isProjectPredefined) && !this.isAllowedToManageProjects) ||
        this.isModifySnappingActive
      );
    },
    isSaveProjectDisabled() {
      return (
        this.isSharedProject || ((this.isProjectDefault || this.isProjectPredefined) && !this.isAllowedToManageProjects)
      );
    },
    currentProjectType() {
      return this.project.role ? this.project.role : this.isSharedProject ? 'shared' : 'owner';
    },
    addLayerTooltip() {
      if (this.isModifySnappingActive) {
        return this.$i18n.t('button.toAddLayer', { reason: this.$i18n.t('button.turnOffEditing') });
      }
      if (this.isSharedProject && !this.isProjectDefault && !this.isProjectPredefined) {
        return this.$i18n.t('sidebar.sharedProjectAddDisabled');
      } else if (this.isProjectPredefined && !this.isAllowedToManageProjects) {
        return this.$i18n.t('sidebar.predefinedProjectAddDisabled');
      } else if (this.isProjectDefault && !this.isAllowedToManageProjects) {
        return this.$i18n.t('sidebar.defaultProjectAddDisabled');
      }
      return this.$i18n.t('button.addLayer');
    },
    addGroupTooltip() {
      if (this.isModifySnappingActive) {
        return this.$i18n.t('button.toAddGroup', { reason: this.$i18n.t('button.turnOffEditing') });
      }
      if (this.isSharedProject && !this.isProjectDefault && !this.isProjectPredefined) {
        return this.$i18n.t('sidebar.sharedProjectAddGroupDisabled');
      } else if (this.isProjectPredefined && !this.isAllowedToManageProjects) {
        return this.$i18n.t('sidebar.predefinedProjectAddGroupDisabled');
      } else if (this.isProjectDefault && !this.isAllowedToManageProjects) {
        return this.$i18n.t('sidebar.defaultProjectAddGroupDisabled');
      }
      return this.$i18n.t('button.addGroup');
    },
    saveProjectTooltip() {
      if (this.isSharedProject && !this.isProjectDefault && !this.isProjectPredefined) {
        return this.$i18n.t('sidebar.sharedProjectSave');
      } else if (this.isProjectDefault && !this.isAllowedToManageProjects) {
        return this.$i18n.t('sidebar.defaultProjectSave');
      } else if (this.isProjectPredefined && !this.isAllowedToManageProjects) {
        return this.$i18n.t('sidebar.predefinedProjectSave');
      } else if (!this.hasUnsavedChanges && !this.isMapViewChanged) {
        return this.$i18n.t('sidebar.noChanges');
      }
      return this.$i18n.t('button.saveChanges');
    },
    createDialogBody() {
      return this.isProjectDefault
        ? this.$i18n.t('dialog.body.confirmProjectCreateFromDefault')
        : this.isProjectPredefined
          ? this.$i18n.t('dialog.body.confirmProjectCreateFromPredefined')
          : this.$i18n.t('dialog.body.confirmProjectCreateFromShared');
    },
  },
  methods: {
    updateLegendStylesCounts: call('legend/updateLegendStylesCounts'),
    createGtagEvent: call('gtag/createEvent'),
    editProject: call('layers/editProject'),
    shareProject: call('layers/shareProject'),
    setDefaultProject: call('layers/setDefaultProject'),
    setPredefinedProject: call('layers/setPredefinedProject'),
    unsetPredefinedProject: call('layers/unsetPredefinedProject'),
    createProject: call('layers/createProject'),
    deleteProject: call('layers/deleteProject'),
    getLayerFeatures: call('layers/getLayerFeatures'),
    openCreateProjectDialog() {
      this.isCreateProjectFromDefaultDialogVisible = true;
      this.isCreateProjectFromDefaultDialogLoaded = true;

      this.createGtagEvent({
        action: 'open_project_create_dialog',
        category: 'map_legend',
      });
    },
    projectChanged({ e, withRouterParse } = {}) {
      if (e !== null) {
        if (e) {
          if (this.$route.query.project === e.id) return;
          this.project = e;
        }
        this.isProjectLayersFetched = false;
        this.hasUnsavedChanges = false;
        this.isMapViewChanged = false;
        this.$root.$emit('fetchProject', { withRouterParse });
      }
    },
    async onWarningAgree() {
      this.isWarningDialogVisible = false;
      this.isWarningDialogLoaded = false;
      this.wasWarningDisplayed = true;
      await this.saveProjectEditing();
      if (this.currentProjectAction) {
        this.showProjectActionDialog(this.currentProjectAction);
      }
    },
    async onWarningDiscard() {
      this.isWarningDialogVisible = false;
      this.isWarningDialogLoaded = false;
      this.wasWarningDisplayed = true;
      await this.discardProjectEditing();
      if (this.currentProjectAction) {
        this.showProjectActionDialog(this.currentProjectAction);
      }
    },
    setCurrentLayer(layer) {
      this.$router.push({
        name: 'layerTable',
        params: {
          lid: layer.id,
        },
      });
    },
    async saveProjectEditing() {
      this.projectCopy = JSON.parse(JSON.stringify(this.project));
      let payload = JSON.parse(JSON.stringify(this.project));
      payload.layers = payload.layers.map(layer => {
        if (typeof layer.id === 'string' && layer.id?.includes('ugi-')) {
          delete layer.id;
        }
        return { ...layer };
      });
      if (this.project.id === 'default') {
        payload.default = true;
        delete payload.id;
      }
      this.createGtagEvent({
        action: 'save_project_editing',
        category: 'map_legend',
        params: {
          name: this.project.name,
          id: this.project.id,
        },
      });

      try {
        this.projectEditingLoading = true;
        await this.editProject({ body: payload, id: this.project.id });
        this.$store.set('snackbar/PUSH_SUCCESSFULLY_MESSAGE!', {
          message: this.$i18n.t('success.successProjectEdit'),
        });
        this.hasUnsavedChanges = false;
        this.isMapViewChanged = false;
        this.wasWarningDisplayed = false;
        this.projectEditingLoading = false;
      } catch (e) {
        console.log(e);
        this.projectEditingLoading = false;
      }
    },
    createProjectFromDefaultHandler() {
      this.createProjectHandler(true);
    },
    discardProjectEditing() {
      this.$store.set('layers/SET_PROJECT!', { ...this.unchangedProject });
      this.$store.set('layers/DISCARD_PROJECT!', { ...this.unchangedProject });
      this.$root.$emit('discardProjectLayers');
      this.hasUnsavedChanges = false;
      this.isMapViewChanged = false;
      this.wasWarningDisplayed = false;
    },
    showShareDialog() {
      this.isShareDialogVisible = true;
      this.isShareDialogLoaded = true;

      this.createGtagEvent({
        action: 'open_project_share_dialog',
        category: 'map_legend',
      });
    },
    async shareProjectHandler() {
      if (this.emailsShare.length > 0) {
        try {
          this.isLoading = true;
          await this.shareProject({ id: this.project.id, userIds: this.emailsShare });
          this.isShareDialogVisible = false;
          this.isShareDialogLoaded = false;
          this.$store.set('snackbar/PUSH_SUCCESSFULLY_MESSAGE!', {
            message: this.$i18n.t('success.successShareProject'),
          });
          this.emailsShare = [];
        } catch (error) {
          this.$store.set('snackbar/PUSH_ERROR!', {
            message: this.$i18n.t(`error['${error.message}']`),
          });
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$store.set('snackbar/PUSH_ERROR!', {
          message: this.$i18n.t('dialog.userNotFound'),
        });
      }
    },
    async actionProjectHandler() {
      if (this.currentProjectAction === 'delete')
        this.createGtagEvent({
          action: 'delete_project',
          category: 'map_legend',
          params: {
            name: this.project.name,
            id: this.project.id,
          },
        });

      try {
        this.isLoading = true;
        await this[`${this.currentProjectAction}Project`](this.project.id);
        const projId = ['delete', 'setDefault'].includes(this.currentProjectAction) ? 'default' : this.project.id;
        this.project = JSON.parse(JSON.stringify(this.projects[projId]));
        this.projectChanged({ withRouterParse: ['delete', 'setDefault'].includes(this.currentProjectAction) });
        this.$store.set('snackbar/PUSH_SUCCESSFULLY_MESSAGE!', {
          message: this.$i18n.t(this.projectActionsTranslations[this.currentProjectAction].successMessagePath),
        });
        this.isProjectActionDialogVisible = false;
        this.isProjectActionDialogLoaded = false;
        this.currentProjectAction = undefined;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    showProjectActionDialog(action) {
      this.currentProjectAction = action;
      if (['setDefault', 'setPredefined', 'unsetPredefined'].includes(action) && this.isDisplayWarning) {
        this.showWarning();
        return;
      }
      this.isProjectActionDialogVisible = true;
      this.isProjectActionDialogLoaded = true;
      if (action === 'delete')
        this.createGtagEvent({
          action: 'open_project_delete_dialog',
          category: 'map_legend',
        });
    },
    showWarning() {
      this.$refs.select.isMenuActive = false;
      this.isWarningDialogVisible = true;
      this.isWarningDialogLoaded = true;
    },
    setProjectName() {
      if (!this.projectName) {
        this.$store.set('snackbar/PUSH_ERROR!', {
          message: this.$i18n.t('errors.projects.nameRequired'),
        });
      } else {
        this.$store.set('layers/SET_PROJECT_NAME!', this.projectName);
        this.projectProxy = JSON.parse(JSON.stringify(this.project));
        this.projectName = '';
        this.hasUnsavedChanges = true;
        this.isChangeNameDialogVisible = false;
        this.isChangeNameDialogLoaded = false;
      }
    },
    projectsCustomFilter(item, queryText, itemText) {
      if (item.isProjectHeader && item.values) {
        return item.values.find(
          project => project.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        );
      } else {
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      }
    },
    changeProjectName() {
      this.projectName = this.project.name;
      this.isChangeNameDialogVisible = true;
      this.isChangeNameDialogLoaded = true;
    },
    async createProjectHandler(asDefault = false) {
      if (!this.projectName) {
        this.$store.set('snackbar/PUSH_ERROR!', {
          message: this.$i18n.t('errors.projects.nameRequired'),
        });
        return;
      }
      try {
        this.isLoading = true;
        const payload = {
          coordinateSystemEpsg: this.$_config.defaultEpsg,
          name: this.projectName,
        };
        if (asDefault || this.isCreateProjectDialogCopy) {
          payload.layers = this.project.layers;
        }
        await this.createProject(payload);
        this.projectChanged();
        this.$store.set('snackbar/PUSH_SUCCESSFULLY_MESSAGE!', {
          message: this.$i18n.t('success.successCreateProject'),
        });
        this.projectName = '';
        this.isCreateProjectDialogVisible = false;
        this.isCreateProjectDialogLoaded = false;
        this.isCreateProjectDialogCopy = false;
        this.isCreateProjectFromDefaultDialogVisible = false;
        this.isCreateProjectFromDefaultDialogLoaded = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    showCreateProjectDialog(e, copy = false) {
      this.$refs['select']?.$refs['data-input']?.closeMenu();
      if (copy) {
        this.isCreateProjectDialogCopy = copy;
      }
      this.isCreateProjectDialogVisible = true;
      this.isCreateProjectDialogLoaded = true;
    },
    showAddLayersDialog() {
      this.isAddLayersDialogVisible = true;

      this.createGtagEvent({
        action: 'open_add_layer_dialog',
        category: 'map_legend',
      });
    },
    showAddGroupDialog() {
      this.isAddGroupDialogLoaded = true;
      this.isAddGroupDialogVisible = true;
    },
    addGroupHandler() {
      const value = JSON.parse(JSON.stringify(this.addGroupInputs.find(input => input.name === 'name').value));
      this.$store.set('layers/ADD_PROJECT_GROUP!', { value, id: `ugi-${this.groupUniqueId}` });
      this.groupUniqueId++;
      this.isAddGroupDialogVisible = false;
      this.addGroupInputs.find(input => input.name === 'name').value = '';
      this.hasUnsavedChanges = true;
      this.$forceUpdate();
    },
    async addLayersHandler(layersChecked) {
      this.isAddingLayerLoading = true;
      const layersToAdd = layersChecked.map(layer => {
        const layerCopy = { layer_id: layer.id, visible: true, opacity: 1, has_permission: true, ...layer };
        delete layerCopy.key;
        delete layerCopy.style_qgis;
        delete layerCopy.style_qgis_file_name;
        if (layer.type === 'features_layer') {
          layerCopy.labels_visible = layerCopy.labels_visible || false;
          layerCopy.style = layerCopy.style_web;
          layerCopy.cache = layerCopy.schema === 'cache' ? true : false;
          delete layerCopy.style_web;
          delete layerCopy.schema;
        } else if (layer.type === 'service_layer') {
          layerCopy.style = layerCopy.style_web;
          delete layerCopy.style_web;
        }
        return layerCopy;
      });
      this.$store.set('layers/ADD_PROJECT_LAYERS!', layersToAdd);
      // const promises = layersToAdd
      //   .filter(layer => layer.type === 'features_layer' && !layer.supports_mvt)
      //   .map(layer => this.getLayerFeatures({ layer_id: layer.id }));
      // await Promise.all(promises);
      this.$root.$emit('pushProjectLayers', layersToAdd);
      this.isAddingLayerLoading = false;
      this.hasUnsavedChanges = true;
      this.layersChecked = [];
      this.isAddLayersDialogVisible = false;

      layersChecked.forEach(layer => {
        this.createGtagEvent({
          action: 'add_layer',
          category: 'map_legend',
          params: {
            type: layer.type,
            name: layer.name,
            id: layer.id,
          },
        });
      });
    },
  },
  mounted() {
    this.$root.$off('addLayersHandler');
    this.$root.$on('addLayersHandler', this.addLayersHandler);
    this.$root.$off('updateLegendStylesCounts');
    this.$root.$on('updateLegendStylesCounts', this.updateLegendStylesCounts);
    this.$root.$off('hasUnsavedChanges');
    this.$root.$on('hasUnsavedChanges', value => {
      this.hasUnsavedChanges = value;
    });
    this.updateLegendStylesCounts();
  },
};
</script>

<style lang="scss" scoped>
.border {
  @include border(bottom);
}
.v-menu__content {
  max-width: 30% !important;
}
.add-project-wrapper {
  position: sticky !important;
  bottom: 0;
  background-color: white;
  z-index: 1;
}
::v-deep .new__project__name .v-input__slot {
  margin-bottom: 0;
}
::v-deep .new__project__name .v-messages__message {
  color: rgba(0, 0, 0, 0.59);
  font-size: 10px;
  font-style: italic;
}
::v-deep .new__project__name .v-text-field__details {
  padding: 0;
}
::v-deep .v-messages__message {
  font-size: 10px;
  font-style: italic;
}
::v-deep .v-input__slot {
  margin-bottom: 3px;
}
::v-deep .main-dialog {
  .v-btn.down {
    margin-left: 14px !important;
    min-width: 90px;
    text-transform: initial;
    &.cancel {
      border-color: rgba(17, 10, 10, 0.2);
      &:hover {
        box-shadow:
          0 3px 3px -2px rgba(0, 0, 0, 0.2),
          0 3px 4px 0 rgba(0, 0, 0, 0.14),
          0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
      }
    }
  }
}
</style>
