import Vue from 'vue';
const state = {
  /**
   * Errors are stored in Array in order to have the backlog of all error that * occured during the app session (not sure if that's required but why not :))
   * @type {Object[]} errors
   * @prop {String} errors[].message - Error message that is displayed in
   * snackbar.
   * @prop {Number} errors[].status - Error code (if the error is a
   * consequence of Network request - not displayed but might be usefull
   * someday).
   */
  errors: [],
  successfullyMessages: [],
  messages: [],
  downloadProgress: {},
};
const mutations = {
  PUSH_ERROR: (state, value) => {
    state.errors.push(value);
  },
  PUSH_MESSAGE: (state, value) => {
    state.messages.push(value);
  },
  PUSH_SUCCESSFULLY_MESSAGE: (state, value) => {
    state.successfullyMessages.push(value);
  },
  SET_DOWNLOAD_PROGRESS: (state, { name, value }) => {
    Vue.set(state.downloadProgress, name, value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
