import { call, get, sync } from 'vuex-pathify';

import { GeoJSON } from 'ol/format';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Fill, Stroke, Circle, Icon } from 'ol/style';
import { Draw, Modify, Snap } from 'ol/interaction';
import { unByKey } from 'ol/Observable';
import { prepareDrawnGeometry, prepareDrawnFeature, getGeodesicArea } from '@/assets/js/mapUtils';
import WKT from 'ol/format/WKT';
import { getDistance } from 'ol/sphere';
import { transform } from 'ol/proj';
import { buffer, getCenter } from 'ol/extent';
import Feature from 'ol/Feature';
import { fromExtent } from 'ol/geom/Polygon';
import booleanIntersects from '@turf/boolean-intersects';
import dissolve from '@turf/dissolve';
import { MultiPoint, MultiLineString, MultiPolygon } from 'ol/geom';
import flatten from '@turf/flatten';

import { minEditingZoom, defaultMinZoom, defaultEpsg } from '@/assets/js/variables';
import geometryTypeMapping from '@/mixins/map/geometryTypeMapping';
import transformInteraction from '@/mixins/map/transformInteraction';
import topo from '@/mixins/topo';

let CABLE_SNAPPING_PROXY_LISTENERS = [];
const PROXY_SOURCE = new VectorSource();
let snappingKey = undefined;

export default {
  mixins: [geometryTypeMapping, transformInteraction, topo],
  computed: {
    dataSources: get('layers/metadata'),
    parcelDataSource: get('admin/modulesMapping@parcels.parcels_datasource'),
    settingsParcelsProvider: get('admin/settingsValues@parcels_provider'),
    isModificationInteractionActive: sync('edit/isModificationInteractionActive'),
    isDrawingInteractionActive: sync('edit/isDrawingInteractionActive'),
    isModifySnappingActive: sync('edit/isModifySnappingActive'),
    zdmDataDataSources: get('admin/modulesMapping@zdm_data.datasources'),
    cableLayer: get('admin/modulesMapping@zdm_data.layers.cable'),
    layersFilters: get('layers/layersFilters'),
    isAssigningMultipleParcelsLoading: sync('edit/isAssigningMultipleParcelsLoading'),
    isAssignParcelButtonActive: sync('edit/isAssignParcelButtonActive'),
    streetLampDataSourceName: get('admin/modulesMapping@zdm_data.datasources.street_lamp.datasource'),
    isSnappingConfigToolActive: sync('tools/toolStatus@isSnappingConfigToolActive'),
    snappingConfigOld: sync('tools/snappingConfigOld'),
    snappingConfigToolToleranceValue: sync('tools/snappingConfigToolToleranceValue'),
    newTopoIndexes: sync('edit/newTopoIndexes'),
    deletedTopoIndexes: sync('edit/deletedTopoIndexes'),
    isModifyMultipartActive: sync('edit/isModifyMultipartActive'),
    drawModifyInteractionsNames() {
      return ['drawingSidebarInteraction', 'modifySidebarInteraction'];
    },
  },
  methods: {
    getFeature: call('layers/getDataSourceFeature'),
    getLayersFeaturesInBbox: call('layers/getLayersFeaturesInBbox'),
    getLayerFeature: call('layers/getLayerFeature'),
    getDataSourceFeatures: call('layers/getDataSourceFeatures'),
    searchUldkParcelByXY: call('search/searchUldkParcelByXY'),
    registerSidebarGeometryListeners() {
      this.$root.$on('assignedCoordinates', this.assignGeometry);
      this.$root.$on('zoomSidebarGeometry', this.zoomSidebarGeometry);
      this.$root.$on('clearSidebarGeometry', this.clearSidebarGeometry);
      this.$root.$on('deleteSidebarGeometry', this.deleteSidebarGeometry);
      this.$root.$on('deleteDrawModifyInteractions', this.deleteDrawModifyInteractions);
      this.$root.$on('drawSidebarGeometry', this.initDrawingSidebarGeometry);
      this.$root.$on('modifySidebarGeometry', this.initModifySidebarGeometry);
      this.$root.$on('deactivateSidebarModify', this.deactivateSidebarModify);
      this.$root.$on('turnOnTransformGeometry', this.initTransformGeometry);
      this.$root.$on('turnOffTransformGeometry', this.removeTransformInteraction);
      this.$root.$on('toggleAddMultipartGeometry', this.toggleAddMultipartGeometry);
      this.$root.$on('toggleRemoveMultipartGeometry', this.toggleRemoveMultipartGeometry);
      this.$root.$on('setDrawingSidebarInteractionCoordinates', this.setDrawingSidebarInteractionCoordinates);
      this.$root.$on('finishDrawingSidebarInteraction', this.finishDrawingSidebarInteraction);
      this.$root.$on('deleteModifySidebarFeature', this.deleteDrawingLayerFeature);
      this.$root.$on('finishAssigningGeometry', this.finishAssigningGeometry);
    },
    turnOffModifySnapping() {
      this.setMinZoom(defaultMinZoom);
      this.$root.$emit('toggleSnappingInteraction', { value: false });
      if (this.snappingConfigOld?.value || this.snappingConfigOld?.value === false) {
        this.isSnappingConfigToolActive = this.snappingConfigOld.value;
      }
      if (this.snappingConfigOld?.toleranceValue) {
        this.snappingConfigToolToleranceValue = this.snappingConfigOld.toleranceValue;
      }
      this.snappingConfigOld = {};
      this.isModifySnappingActive = false;
      unByKey(snappingKey);
    },
    initModifySnapping({ activeLayer = null, forceDefaultSnapping = false, snappingAdditionalLayers = [] } = {}) {
      this.snappingConfigOld = {
        value: JSON.parse(JSON.stringify(this.isSnappingConfigToolActive)),
        toleranceValue: JSON.parse(JSON.stringify(this.snappingConfigToolToleranceValue)),
      };
      if (
        forceDefaultSnapping ||
        (this.layers[activeLayer || this.layerId]?.layer_scope &&
          this.layers[activeLayer || this.layerId]?.layer_scope !== 'core')
      ) {
        this.isSnappingConfigToolActive = true;
        this.snappingConfigToolToleranceValue = 10;
      }
      if (!this.isSnappingConfigToolActive) {
        this.isModifySnappingActive = true;
        return;
      }
      const isZoom = this.map.getView().getZoom() < minEditingZoom;
      if (isZoom) {
        this.setView({ zoom: minEditingZoom });
        this.$store.set('snackbar/PUSH_SUCCESSFULLY_MESSAGE!', {
          message: this.$i18n.t('map.zoomedToMinEditingZoom'),
        });
      }
      this.setMinZoom(minEditingZoom);
      const snappingSource = new VectorSource({
        name: 'snappingSource',
      });
      this.initSnapping(snappingSource);
      this.attachSnappingLoader(snappingSource, isZoom, activeLayer, snappingAdditionalLayers);
      this.isModifySnappingActive = true;
    },
    async attachSnappingLoader(source, isZoom, activeLayer = null, snappingAdditionalLayers = []) {
      const activeLayerId = parseInt(activeLayer || this.layerId);
      const visibleLayersIds = this.projectLayers
        .filter(
          layer => layer.visible && layer.id !== activeLayerId && layer.type === 'features_layer' && layer.geometry_type
        )
        .map(layer => layer.id);
      // wrappnięta funkcja setSnappingFeatures, żeby nie pisać dwa razy tego samego
      const snappingFunctionHandler = extent => {
        return this.setSnappingFeatures(
          source,
          visibleLayersIds,
          activeLayerId,
          this.getExtentWithBuffer(extent, 100),
          this.layersFilters[activeLayerId]?.filterExpression,
          snappingAdditionalLayers
        );
      };
      // nie trzeba było przybliżać (więc nie wywoła się event moveend), czyli od razu pobieramy ficzery w bboxie
      if (!isZoom) {
        snappingFunctionHandler(this.map.getView().calculateExtent());
      }
      // przypinamy listener na event moveend, żeby pobrać ficzery w bboxie
      snappingKey = this.map.on('moveend', e => {
        snappingFunctionHandler(e.map.getView().calculateExtent());
      });
    },
    async setSnappingFeatures(source, ids, activeLayerId, extent, activeFeatureFilter, snappingAdditionalLayers = []) {
      const featuresCollection = await this.getSnappingGeoJsonFeatures(
        ids,
        activeLayerId,
        extent,
        activeFeatureFilter,
        snappingAdditionalLayers
      );
      source.clear(true);
      source.addFeatures(
        new GeoJSON().readFeatures(featuresCollection, {
          featureProjection: featuresCollection.crs.properties.name,
          dataProjection: defaultEpsg,
        })
      );
    },
    getExtentWithBuffer(extent, bufferValue = 100) {
      return buffer(extent, bufferValue);
    },
    async getSnappingGeoJsonFeatures(ids, activeLayerId, extent, activeFeatureFilter, snappingAdditionalLayers = []) {
      let snappingAdditionalFeatures = [];
      for (const layer of snappingAdditionalLayers.filter(layer => layer.gjson)) {
        const { id: layerId, group } = layer;
        const mapLayer = this.getLayerById(layerId, group);
        if (!mapLayer) {
          continue;
        }
        const features = mapLayer.getSource()?.getFeatures();
        snappingAdditionalFeatures = [
          ...snappingAdditionalFeatures,
          ...new GeoJSON().writeFeaturesObject(features).features,
        ];
      }
      try {
        const r = await this.getLayersFeaturesInBbox({
          active_features_filter: activeFeatureFilter,
          active_layer_id: activeLayerId,
          bbox: prepareDrawnGeometry(new Feature({ geometry: fromExtent(extent) })),
          ids: [...ids, ...snappingAdditionalLayers.filter(layer => !layer.gjson).map(layer => layer.id)],
        });
        return {
          ...r.features,
          features: [...r.features.features, ...snappingAdditionalFeatures].map((feature, idx) => {
            return { ...feature, id: idx };
          }),
        };
      } catch (error) {
        console.log('  error', error);
      }
    },
    finishAssigningGeometry(geometryType, layerId) {
      const features = this.getLayerById('drawingSidebarLayer').getSource().getFeatures();
      let geomColl;
      const ftCollection = new GeoJSON().writeFeaturesObject(features);
      const dissolvedCollection = dissolve(flatten(ftCollection));
      if (geometryType === 'multipolygon') {
        const geom = new MultiPolygon([]);
        dissolvedCollection.features.forEach(ft => {
          geom.appendPolygon(new GeoJSON().readGeometry(ft.geometry));
        });
        geomColl = new GeoJSON().writeGeometryObject(geom);
      } else {
        geomColl = dissolvedCollection.features[0].geometry;
      }
      geomColl.crs = {
        properties: {
          name: 'EPSG:3857',
        },
        type: 'name',
      };
      this.$root.$emit('identification-action', false);
      this.activeTool = 'moduleIdentification';
      this.dettachCursorMoveHandler();
      this.$root.$emit('deleteSidebarGeometry');
      this.initModifySidebarGeometry({
        modifyendCallback: e => {
          this.emitSidebarGeometry(e, 'newPolygonFeatureGeometry');
        },
        isInitSnapping: false,
        geometryValue: geomColl,
      });
      this.$root.$emit('addedSidebarGeometry', {
        inputName: 'newPolygonFeatureGeometry',
        layerId,
        geometry: geomColl,
      });
    },
    async assignGeometry(
      coordinates,
      name,
      isTurnOffOnFail = true,
      isMultiple = false,
      geometryType,
      identifyCallback,
      forcedProvider,
      onError
    ) {
      try {
        if (isMultiple) {
          const layerSource = this.getLayerById('drawingSidebarLayer')?.getSource();
          if (layerSource?.getFeatures()?.length) {
            const foundFeatures = layerSource.getFeaturesAtCoordinate(coordinates);
            if (foundFeatures.length) {
              if (geometryType === 'polygon') {
                const filteredGeom = new GeoJSON().writeFeaturesObject(
                  layerSource.getFeatures().filter(ft => ft !== foundFeatures[0])
                );
                if (dissolve(flatten(filteredGeom)).features.length > 1) {
                  this.$store.set('snackbar/PUSH_MESSAGE!', {
                    message: this.$i18n.t('snackbar.wrongPolygonGeometry'),
                  });
                  return;
                }
              }
              layerSource.removeFeature(foundFeatures[0]);
              return;
            }
          }
        }
        const data = await this.getAssignedGeometry(coordinates, name, isTurnOffOnFail, forcedProvider);
        const returnedGeometry = data?.geometry;
        if (!returnedGeometry) return onError ? onError(data) : undefined;
        if (isMultiple) {
          if (!this.getLayerById('drawingSidebarLayer')) this.initDrawingLayer();
          if (geometryType === 'polygon') {
            const layerFeatures = this.getLayerById('drawingSidebarLayer').getSource().getFeatures();
            if (layerFeatures.length) {
              let geom = new GeoJSON().writeFeaturesObject(layerFeatures);
              const dissolved = dissolve(flatten(geom));
              const isIntersecting = booleanIntersects(returnedGeometry, dissolved.features[0]);
              if (!isIntersecting) {
                this.$store.set('snackbar/PUSH_MESSAGE!', {
                  message: this.$i18n.t('snackbar.wrongPolygonGeometry'),
                });
                return onError ? onError(data) : undefined;
              }
            }
          }
          this.addDrawingLayerFeature(returnedGeometry);
        } else {
          this.$root.$emit('identification-action', false);
          if (data.additionalParams.isNewPolygonFeatureGeometry) {
            this.activeTool = 'moduleIdentification';
          }
          this.dettachCursorMoveHandler();
          this.$root.$emit('deleteSidebarGeometry');
          this.initModifySidebarGeometry({
            modifyendCallback: e => {
              this.emitSidebarGeometry(e, name);
            },
            isInitSnapping: false,
            geometryValue: returnedGeometry,
          });
          const returnData = {
            inputName: name,
            geometry: returnedGeometry,
            ...(data.additionalParams.attributesValues && { attributesValues: data.additionalParams.attributesValues }),
            ...(data.additionalParams.area && { area: data.additionalParams.area }),
          };
          identifyCallback ? identifyCallback(returnData) : this.$root.$emit('addedSidebarGeometry', returnData);
        }
        this.isAssigningMultipleParcelsLoading = this.isAssignParcelButtonActive = false;
      } finally {
        this.isAssigningMultipleParcelsLoading = false;
      }
    },
    async getAssignedGeometry(coordinates, name, isTurnOffOnFail = true, forcedProvider) {
      const isNewPolygonFeatureGeometry = name === 'newPolygonFeatureGeometry';
      if (forcedProvider === 'uldk' || this.settingsParcelsProvider === 'uldk') {
        const payload = {
          coords: {
            x: coordinates[0],
            y: coordinates[1],
            epsg: this.$_config.defaultEpsg.split(':')[1],
            params: ['geom_wkt', 'teryt', 'voivodeship', 'county', 'commune', 'region', 'parcel'],
          },
        };
        const r = await this.searchUldkParcelByXY(payload);
        const { voivodeship, county, commune, region, teryt, parcel } = r;
        if (r !== null && r.error) {
          this.$store.set('snackbar/PUSH_MESSAGE!', {
            message: this.$i18n.t('snackbar.notFoundPlotWithError', { error: r.error }),
          });
          if (isTurnOffOnFail) {
            this.$root.$emit('identification-action', false);
            if (isNewPolygonFeatureGeometry) {
              this.activeTool = 'moduleIdentification';
            }
          }
          return;
        } else if (Object.keys(r).length < 1) {
          this.$store.set('snackbar/PUSH_MESSAGE!', {
            message: this.$i18n.t('snackbar.notFoundPlot'),
          });
          if (isTurnOffOnFail) {
            this.$root.$emit('identification-action', false);
            if (isNewPolygonFeatureGeometry) {
              this.activeTool = 'moduleIdentification';
            }
          }
          return;
        }
        let wktText = r.geom_wkt;
        if (wktText.indexOf(';') >= 0) {
          wktText = wktText.split(';')[1];
        }
        const feature = new WKT().readFeature(wktText, {
          dataProjection: this.$_config.defaultEpsg,
          featureProjection: this.$_config.defaultEpsg,
        });
        const geoJsonFeature = new GeoJSON().writeFeatureObject(feature, {
          featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
          dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
        });
        geoJsonFeature.geometry.crs = {
          properties: {
            name: this.$_config.defaultEpsg || 'EPSG:4326',
          },
          type: 'name',
        };
        return {
          geometry: geoJsonFeature.geometry,
          source: this.settingsParcelsProvider,
          additionalParams: {
            isNewPolygonFeatureGeometry,
            attributesValues: { voivodeship, county, commune, region, teryt, parcel },
            area: getGeodesicArea(feature.getGeometry()),
          },
        };
      } else {
        const point = {
          type: 'Point',
          crs: {
            type: 'name',
            properties: {
              name: this.$_config.defaultEpsg || 'EPSG:4326',
            },
          },
          coordinates: coordinates,
        };
        const r = await this.getDataSourceFeatures({
          dataSource: this.parcelDataSource,
          filters: {
            '%SPATIALLY_INTERSECTS': {
              [`${this.parcelDataSource}.${
                this.dataSources[this.parcelDataSource]?.attributes_schema.geometry_name ?? 'geom'
              }`]: [point],
            },
          },
        });
        if (r.features.length === 0) {
          this.$store.set('snackbar/PUSH_MESSAGE!', {
            message: this.$i18n.t('snackbar.notFoundPlot'),
          });
          if (isTurnOffOnFail) {
            this.$root.$emit('identification-action', false);
            if (isNewPolygonFeatureGeometry) {
              this.activeTool = 'moduleIdentification';
            }
          }
          return;
        } else {
          return {
            geometry: {
              coordinates: r.features[0].geometry.coordinates,
              crs: r.crs,
              type: r.features[0].geometry.type,
            },
            source: this.settingsParcelsProvider,
            additionalParams: {
              isNewPolygonFeatureGeometry,
            },
          };
        }
      }
    },
    deactivateSidebarModify() {
      const interaction = this.getInteractionByName('modifySidebarInteraction');
      interaction && interaction.setActive(false);
      this.isModificationInteractionActive = false;
    },
    deleteDrawModifyInteractions(namesArray = this.drawModifyInteractionsNames) {
      namesArray.forEach(name => this.deleteInteraction(name));
    },
    deleteInteraction(name) {
      const interaction = this.getInteractionByName(name);
      if (interaction) {
        this.map.removeInteraction(interaction);
      }
    },
    clearSidebarGeometry() {
      const layer = this.getLayerById('drawingSidebarLayer');
      if (layer) {
        layer.getSource().clear();
      }
    },
    deleteSidebarGeometry() {
      this.clearSidebarGeometry();
      this.deleteDrawModifyInteractions();
      this.deactivateCableSnapping();
      this.isModificationInteractionActive = false;
      this.isModifyMultipartActive = false;
      this.isDrawingInteractionActive = false;
      this.setMinZoom(defaultMinZoom);
      this.turnOffModifySnapping();
    },
    zoomSidebarGeometry() {
      const layer = this.getLayerById('drawingSidebarLayer');
      if (!layer) {
        return;
      }
      this.fitView(layer.getSource().getExtent());
    },
    emitSidebarGeometry(e, name) {
      let sidebarFeature = e.feature;
      if (!sidebarFeature && e.features) {
        sidebarFeature = e.features.getArray()[0];
      }
      if (!sidebarFeature) {
        this.$root.$emit('addedSidebarGeometry', { inputName: name, geometry: null });
      } else {
        this.$root.$emit('addedSidebarGeometry', { inputName: name, geometry: prepareDrawnGeometry(sidebarFeature) });
      }
    },
    selectionCircleLabelsStyle(feature, type) {
      const geometry = feature?.getGeometry();
      if (!geometry || type !== 'Circle' || geometry?.getType() !== 'Polygon') return;
      return this.getCircleLabelStyle(
        getCenter(geometry.getExtent()),
        getDistance(
          transform(getCenter(geometry.getExtent()), 'EPSG:3857', 'EPSG:4326'),
          transform(geometry.getFirstCoordinate(), 'EPSG:3857', 'EPSG:4326')
        ),
        ['radius']
      );
    },
    defaultSelectionStyle() {
      return new Style({
        fill: new Fill({
          color: 'rgba(63, 127, 191, 0.4)',
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 255, 1)',
          width: 2,
        }),
        image: new Circle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 255, 1)',
          }),
          fill: new Fill({
            color: 'rgba(63, 127, 191, 0.4)',
          }),
        }),
      });
    },
    initDrawingLayer({ marker = false, isSelection = false, styleFunction, additionalStyleFunction } = {}) {
      const layer = this.getLayerById('drawingSidebarLayer');
      if (layer) {
        this.clearSidebarGeometry();
        layer.setStyle(this.getDrawingStyleFunction(marker, isSelection, styleFunction, additionalStyleFunction));
        return;
      }
      this.map.addLayer(
        new VectorLayer({
          id: 'drawingSidebarLayer',
          isEditingLayer: true,
          source: new VectorSource(),
          style: this.getDrawingStyleFunction(marker, isSelection, styleFunction, additionalStyleFunction),
          zIndex: 1000,
        })
      );
    },
    getDrawingStyleFunction(marker, isSelection, styleFunction, additionalStyleFunction) {
      if (styleFunction) {
        return styleFunction;
      }
      return ft => {
        return [
          ...(additionalStyleFunction ? additionalStyleFunction(ft) : []),
          marker
            ? new Style({
                image: new Icon({
                  anchor: [0.5, 43],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'pixels',
                  src: '/marker2.png',
                }),
              })
            : isSelection
              ? this.defaultSelectionStyle()
              : new Style({
                  geometry: feature => {
                    const modifyGeometry = feature.get('modifyGeometry');
                    return modifyGeometry ? modifyGeometry : feature.getGeometry();
                  },
                  fill: new Fill({
                    color: 'rgba(200,0,0,0.6)',
                  }),
                  stroke: new Stroke({
                    color: 'rgba(255,0,0,1)',
                    width: 2,
                  }),
                  image: new Circle({
                    radius: 5,
                    stroke: new Stroke({
                      color: 'rgba(0, 0, 255, 1)',
                    }),
                    fill: new Fill({
                      color: 'rgba(63, 127, 191, 0.7)',
                    }),
                  }),
                }),
        ];
      };
    },
    getDrawInteraction({
      geometryType,
      drawendCallback,
      geometryFunction,
      marker = false,
      condition,
      freehand = false,
      maxPoints,
      isDeactivateDrawingInteraction = true,
      layerId,
      additionalStyleFunction,
      isSelection = false,
      drawType,
      finishCondition,
    }) {
      const interaction = new Draw({
        source: this.getLayerById('drawingSidebarLayer').getSource(),
        type: drawType || geometryType,
        condition,
        geometryFunction,
        freehand,
        maxPoints,
        ...(finishCondition ? { finishCondition } : {}),
        style: feature => {
          return [
            ...(additionalStyleFunction ? additionalStyleFunction(feature) : []),
            ...(marker
              ? [
                  new Style({
                    image: new Icon({
                      anchor: [0.5, 43],
                      anchorXUnits: 'fraction',
                      anchorYUnits: 'pixels',
                      src: '/marker2.png',
                    }),
                  }),
                ]
              : isSelection
                ? [
                    this.defaultSelectionStyle(),
                    ...(this.selectionCircleLabelsStyle(feature, geometryType)
                      ? [this.selectionCircleLabelsStyle(feature, geometryType)]
                      : []),
                  ]
                : [
                    new Style({
                      fill: new Fill({
                        color: 'rgba(63, 127, 191, 0.7)',
                      }),
                      stroke: new Stroke({
                        color: 'rgba(0, 0, 255, 1)',
                        width: 2,
                      }),
                      image: new Circle({
                        radius: 5,
                        stroke: new Stroke({
                          color: 'rgba(0, 0, 255, 1)',
                        }),
                        fill: new Fill({
                          color: 'rgba(63, 127, 191, 0.7)',
                        }),
                      }),
                    }),
                  ]),
          ];
        },
      });
      interaction.set('name', 'drawingSidebarInteraction');
      interaction.on('drawend', e => {
        setTimeout(async () => {
          const drawingLayerFeatures = this.getLayerById('drawingSidebarLayer').getSource().getFeatures();
          const cableSnappingInteraction = this.getInteractionByName('cableSnapping');
          if (cableSnappingInteraction) {
            const [firstFeature, lastFeature] = this.validateCable(e.feature);
            // Obiekt poprawnie zwalidowany
            if (firstFeature && lastFeature) {
              // Tworzymy nowy feature ze zmienioną geometrią
              // Pierwszy i ostatni punkt pochodzi z istniejących obiektów
              const preparedFeature = await this.prepareValidatedGeometry(e.feature, firstFeature, lastFeature);
              // Podmieniamy feature w eventcie
              e.feature = preparedFeature;
              drawendCallback(e, drawingLayerFeatures);
            } else {
              // Obiekt niepoprawnie zwalidowany, usunięcie
              this.$store.set('snackbar/PUSH_ERROR!', {
                message: this.$i18n.t('errors.invalidCableGeometry'),
              });
              this.clearSidebarGeometry();
              return;
            }
          } else {
            if (layerId && this.isStreetLampDataSource(layerId)) {
              e.feature = this.roundFeaturePoint(e.feature);
            }
            drawendCallback(e, drawingLayerFeatures);
          }
          if (isDeactivateDrawingInteraction) {
            this.deactivateCableSnapping();
            interaction.setActive(false);
            this.isDrawingInteractionActive = false;
          }
        });
      });
      return interaction;
    },
    roundFeaturePoint(feature) {
      const coordinates = feature.clone().getGeometry().getCoordinates();
      return new GeoJSON().readFeature(
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coordinates.map(coordinate => parseFloat(coordinate.toFixed(8))),
          },
          properties: {},
        },
        {
          featureProjection: 'EPSG:2180',
          dataProjection: 'EPSG:2180',
        }
      );
    },
    deactivateCableSnapping() {
      const cableSnappingInteraction = this.getInteractionByName('cableSnapping');
      if (!cableSnappingInteraction) {
        return;
      }
      // Czyszczenie pozostałości po snapowaniu
      for (const listener of CABLE_SNAPPING_PROXY_LISTENERS) {
        unByKey(listener);
      }
      CABLE_SNAPPING_PROXY_LISTENERS = [];
      PROXY_SOURCE.clear();
      this.map.removeInteraction(cableSnappingInteraction);
    },
    validateCableSnapping(feature) {
      const geometry = feature.getGeometry();
      const [firstPoint, lastPoint] = [geometry.getFirstCoordinate(), geometry.getLastCoordinate()];
      return (
        this.validateIsPointOnAnyFeature(firstPoint, PROXY_SOURCE.getFeatures()) &&
        this.validateIsPointOnAnyFeature(lastPoint, PROXY_SOURCE.getFeatures())
      );
    },
    getLayerObject(layerId, featureId) {
      const gjsonLayers = ['street_lamp', 'cabinet', 'power_station'];
      if (gjsonLayers.includes(layerId)) {
        const layer = this.getLayerById(layerId, 'zdmEditorLayers');
        const features = layer?.getSource()?.getFeatures();
        const feature = features.find(feature => feature.get('featureId') === featureId);
        const coords = feature.getGeometry()?.getCoordinates();
        return { geometry: { coordinates: coords } };
      } else {
        return this.getLayerFeature({
          layer_id: layerId,
          feature_id: featureId,
        });
      }
    },
    async prepareValidatedGeometry(feature, firstFeature, lastFeature) {
      const [rLeft, rRight] = await Promise.all([
        this.getLayerObject(firstFeature.get('layerId'), firstFeature.get('featureId')),
        this.getLayerObject(lastFeature.get('layerId'), lastFeature.get('featureId')),
      ]);
      const coordinates = feature.clone().getGeometry().getCoordinates();
      coordinates[0] = rLeft.geometry.coordinates;
      coordinates[coordinates.length - 1] = rRight.geometry.coordinates;
      return new GeoJSON().readFeature(
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates,
          },
          properties: {},
        },
        {
          featureProjection: 'EPSG:2180',
          dataProjection: 'EPSG:2180',
        }
      );
    },
    validateCable(feature) {
      const geometry = feature.getGeometry();
      const [firstPoint, lastPoint] = [geometry.getFirstCoordinate(), geometry.getLastCoordinate()];
      let firstFeature, lastFeature;
      const [xPointFirst, yPointFirst] = firstPoint.map(coordinate => coordinate.toFixed(3));
      const [xPointLast, yPointLast] = lastPoint.map(coordinate => coordinate.toFixed(3));
      for (const feature of PROXY_SOURCE.getFeatures()) {
        const [x, y] = feature
          .getGeometry()
          .getCoordinates()
          .map(coordinate => coordinate.toFixed(3));
        if (xPointFirst === x && yPointFirst === y) {
          firstFeature = feature;
        }
        if (xPointLast === x && yPointLast === y) {
          lastFeature = feature;
        }
      }
      return [firstFeature, lastFeature];
    },
    validateIsPointOnAnyFeature(point, features) {
      const [xPoint, yPoint] = point.map(coordinate => coordinate.toFixed(0));
      return features.some(feature => {
        const [x, y] = feature
          .getGeometry()
          .getCoordinates()
          .map(coordinate => coordinate.toFixed(0));
        return xPoint === x && yPoint === y;
      });
    },
    initCableSnapping(layersToSnapIds) {
      // Dla każdej warstwy dodaj proxy zapisujące ficzery z kafli do vector source
      for (const layer of layersToSnapIds) {
        const { id: layerId, group, gjson } = layer;
        const mapLayer = this.getLayerById(layerId, group);
        if (!mapLayer) {
          continue;
        }
        if (gjson) {
          const features = mapLayer.getSource()?.getFeatures();
          PROXY_SOURCE.addFeatures(features);
          continue;
        }
        const listener = mapLayer.getSource().on('tileloadend', evt => {
          const features = evt.tile.getFeatures();
          const featureCollection = {
            type: 'FeatureCollection',
            features: features.map(feature => {
              return {
                type: 'Feature',
                properties: {
                  layerId,
                  featureId: feature.getId(),
                },
                geometry: {
                  type: 'Point',
                  coordinates: feature.flatCoordinates_,
                },
              };
            }),
          };
          const featuresObj = new GeoJSON().readFeatures(featureCollection, {
            featureProjection: 'EPSG:2180',
            dataProjection: 'EPSG:2180',
          });
          PROXY_SOURCE.addFeatures(featuresObj);
        });
        CABLE_SNAPPING_PROXY_LISTENERS.push(listener);
        // Odśwież warstwę żeby pobrać dane do proxy
        this.refreshMvtSource(layerId);
      }
      const snap = new Snap({
        source: PROXY_SOURCE,
      });
      snap.set('name', 'cableSnapping');
      this.map.addInteraction(snap);
    },
    handleDrawInteractionAdding({
      geometryType,
      drawendCallback = this.emitSidebarGeometry,
      geometryFunction,
      marker = false,
      condition,
      freehand = false,
      maxPoints,
      isDeactivateDrawingInteraction = true,
      layerId,
      isSelection = false,
      drawType,
      finishCondition,
      additionalStyleFunction,
      snappingAdditionalLayers = [],
    }) {
      const interaction = this.getInteractionByName('drawingSidebarInteraction');
      if (interaction) {
        this.map.removeInteraction(interaction);
      }
      this.map.addInteraction(
        this.getDrawInteraction({
          geometryType,
          drawendCallback,
          geometryFunction,
          marker,
          condition,
          freehand,
          maxPoints,
          isDeactivateDrawingInteraction,
          layerId,
          isSelection,
          drawType,
          additionalStyleFunction,
          finishCondition,
        })
      );
      this.isDrawingInteractionActive = true;
      if (layerId && this.isCableDataSource(layerId) && !this.getInteractionByName('cableSnapping')) {
        this.handleCableSnappingAdding({ snappingAdditionalLayers });
      }
    },
    getModifyInteraction({
      modifyendCallback,
      geometryValue,
      insertVertexCondition,
      isTopoVertices = false,
      geometryType,
      condition,
    }) {
      const isExtent = geometryType === 'Extent';
      const interaction = new Modify({
        source: this.getLayerById('drawingSidebarLayer').getSource(),
        insertVertexCondition: isExtent ? () => false : insertVertexCondition,
        ...(isExtent && { deleteCondition: () => false }),
        style: feature => {
          if (isExtent) {
            feature.get('features').forEach(function (modifyFeature) {
              let modifyGeometry = modifyFeature.get('modifyGeometry');
              if (modifyGeometry) {
                const pointCoordinates = feature.getGeometry().getCoordinates();
                const polygonCoordinates = modifyFeature.getGeometry().getCoordinates()[0];
                const polygonVertexIndex = polygonCoordinates.findLastIndex(
                  coords => coords[0] === pointCoordinates[0] && coords[1] === pointCoordinates[1]
                );
                const diagonalVertexIndex = polygonVertexIndex + 2;
                const diagonalVertex = modifyFeature.getGeometry().getCoordinates()[0][
                  diagonalVertexIndex > polygonCoordinates.length - 1
                    ? diagonalVertexIndex - (polygonCoordinates.length - 1)
                    : diagonalVertexIndex
                ];
                const tempMultiPoint = new MultiPoint([pointCoordinates, diagonalVertex]);
                const newPolygon = fromExtent(tempMultiPoint.getExtent());
                modifyGeometry.setCoordinates(newPolygon.getCoordinates());
              }
            });
          }
          return new Style({
            image: new Circle({
              radius: 5,
              stroke: new Stroke({
                color: 'rgba(255,0,0,1)',
              }),
              fill: new Fill({
                color: 'rgba(255,0,0,0.7)',
              }),
            }),
          });
        },
        ...(condition ? { condition } : {}),
      });
      interaction.set('name', 'modifySidebarInteraction');
      // Cache geometry to get it in 'modifyend' event
      let cachedGeometry = geometryValue ? JSON.parse(JSON.stringify(geometryValue)) : {};
      const originalCoords = JSON.parse(JSON.stringify(cachedGeometry?.coordinates?.[0] || []));
      if (isTopoVertices) {
        // New edition, so to be sure, clear the index lists
        this.newTopoIndexes = [];
        this.deletedTopoIndexes = [];
      }
      if (isExtent) {
        interaction.on('modifystart', e => {
          e.features.forEach(feature => {
            const geometry = feature.getGeometry();
            feature.set('modifyGeometry', geometry.clone(), true);
          });
        });
      }
      interaction.on('modifyend', e => {
        if (isExtent) {
          e.features.forEach(feature => {
            const modifyGeometry = feature.get('modifyGeometry');
            if (modifyGeometry) {
              feature.setGeometry(modifyGeometry);
              feature.unset('modifyGeometry', true);
            }
          });
        }
        if (isTopoVertices) this.topoVerticesModifyendAction(e, cachedGeometry, originalCoords, geometryType);
        setTimeout(() => {
          const cableSnappingInteraction = this.getInteractionByName('cableSnapping');
          if (cableSnappingInteraction) {
            // Obiekt poprawnie zwalidowany
            if (this.validateCableSnapping(e.features.getArray()[0])) {
              if (Array.isArray(cachedGeometry)) {
                cachedGeometry = e.features.getArray().map(feature => prepareDrawnFeature(feature));
              } else {
                cachedGeometry.coordinates = e.features.getArray()[0].getGeometry().getCoordinates();
              }
              modifyendCallback(e);
            } else {
              // Obiekt niepoprawnie zwalidowany, ustawienie na poprzedni poprawnie zwalidowany obiekt
              this.$store.set('snackbar/PUSH_ERROR!', {
                message: this.$i18n.t('errors.invalidCableGeometry'),
              });
              this.clearSidebarGeometry();
              this.addDrawingLayerFeature(cachedGeometry);
              return;
            }
          } else {
            cachedGeometry.coordinates = e.features?.getArray()?.[0]?.getGeometry()?.getCoordinates() || [];
            modifyendCallback(e);
          }
        });
      });
      return interaction;
    },
    topoVerticesModifyendAction(e, cachedGeometry, originalCoords, geometryType) {
      // Get coords before and after last modify
      let oldCoords = JSON.parse(JSON.stringify(cachedGeometry?.coordinates?.[0] || []));
      let newCoords = [...(e.features?.getArray()[0]?.getGeometry()?.getCoordinates()?.[0] || [])];
      if (geometryType === 'multipolygon') {
        oldCoords = oldCoords[0] || [];
        newCoords = newCoords[0] || [];
        originalCoords = originalCoords[0] || [];
      }
      if (oldCoords.length > newCoords.length) {
        // There are more old coords, so vertex has been removed
        const deletedVertexIndex = oldCoords.findIndex((element, idx) => {
          return JSON.stringify(element) !== JSON.stringify(newCoords[idx]);
        });
        if (this.newTopoIndexes.includes(deletedVertexIndex)) {
          // Deleted vertex that was added in this editing session
          this.$store.set('edit/SPLICE_TOPO_INDEX!', { type: 'new', value: deletedVertexIndex });
          this.newTopoIndexes = this.newTopoIndexes.map(aI => {
            // Decrease indexes of the vertices that are after the deleted vertex
            return deletedVertexIndex < aI ? aI - 1 : aI;
          });
        } else {
          // Vertex removed from original pre-edit geometry
          // Decrease value of deleted index by number of preceding new indexes
          const fixedDeletedIdx = deletedVertexIndex - this.newTopoIndexes.filter(aI => aI < deletedVertexIndex).length;
          // Get index increased by the number of preceding deleted indexes
          const correctDeletedIdx = this.getCorrectDeletedIndex(fixedDeletedIdx, this.deletedTopoIndexes);
          this.$store.set('edit/PUSH_TOPO_INDEX!', { type: 'deleted', value: correctDeletedIdx });
          this.newTopoIndexes = this.newTopoIndexes.map(aI => {
            // Increase indexes of the vertices that are after the new vertex
            return correctDeletedIdx < aI ? aI - 1 : aI;
          });
        }
      } else if (oldCoords.length < newCoords.length) {
        // There are more new coords, so vertex has been added
        const newVertexIndex = newCoords.findIndex((element, idx) => {
          return JSON.stringify(element) !== JSON.stringify(oldCoords[idx]);
        });
        this.newTopoIndexes = this.newTopoIndexes.map(aI => {
          // Increase indexes of the vertices that are after the new vertex
          return newVertexIndex <= aI ? aI + 1 : aI;
        });
        this.$store.set('edit/PUSH_TOPO_INDEX!', { type: 'new', value: newVertexIndex });
      }
      if (newCoords.length === originalCoords.length) {
        // Check if the added and deleted indexes are not the same - if so, this can be simplified to just editing
        if (JSON.stringify(this.newTopoIndexes) === JSON.stringify(this.deletedTopoIndexes)) {
          this.newTopoIndexes = [];
          this.deletedTopoIndexes = [];
        }
      }
    },
    getCorrectDeletedIndex(index, arr) {
      const lowerOrEqualIdxes = arr.filter(arrI => arrI <= index);
      const numberOfLowerOrEqualIdxes = lowerOrEqualIdxes.length;
      if (numberOfLowerOrEqualIdxes) {
        const biggerIdxes = arr.filter(arrI => arrI > index);
        index = index + numberOfLowerOrEqualIdxes;
        return this.getCorrectDeletedIndex(index, biggerIdxes);
      } else {
        return index;
      }
    },
    handleModifyInteractionAdding({
      modifyendCallback = this.emitSidebarGeometry,
      layerId,
      geometryValue,
      geometryType,
      isInitSnapping = true,
      snappingAdditionalLayers = [],
      activeLayer = null,
      insertVertexCondition,
      condition,
    }) {
      const interaction = this.getInteractionByName('modifySidebarInteraction');
      if (interaction) {
        this.map.removeInteraction(interaction);
      }
      const layer = this.layers[activeLayer || this.layerId];
      geometryType = geometryType ?? layer?.geometry_type;
      const isTopoVertices =
        this.isTopologicalLayer(layer) && ['multilinestring', 'multipolygon'].includes(geometryType);
      this.map.addInteraction(
        this.getModifyInteraction({
          modifyendCallback,
          geometryValue,
          insertVertexCondition,
          isTopoVertices,
          geometryType,
          condition,
        })
      );
      this.isModificationInteractionActive = true;
      if (layerId && this.isCableDataSource(layerId)) {
        if (!this.getInteractionByName('cableSnapping')) {
          this.handleCableSnappingAdding({ snappingAdditionalLayers });
        }
      } else if (isInitSnapping) {
        if (!this.getInteractionByName('snappingInteraction')) {
          this.initModifySnapping({ activeLayer, snappingAdditionalLayers });
        }
      }
    },
    isCableDataSource(layerId) {
      const layer = this.getLayerById(layerId, 'layers');
      return layer && layer?.get('data_source_name') === this.zdmDataDataSources?.cable?.datasource;
    },
    isStreetLampDataSource(layerId) {
      return this.getLayerById(layerId, 'layers')?.get('data_source_name') === this.streetLampDataSourceName;
    },
    handleCableSnappingAdding({ snappingAdditionalLayers = [] } = {}) {
      this.snappingConfigOld = {
        value: JSON.parse(JSON.stringify(this.isSnappingConfigToolActive)),
        toleranceValue: JSON.parse(JSON.stringify(this.snappingConfigToolToleranceValue)),
      };
      this.isSnappingConfigToolActive = true;
      this.snappingConfigToolToleranceValue = 10;
      if (this.getInteractionByName('cableSnapping')) return;
      const layersToSnapIds = [
        ...this.getLayerById('layers')
          .getLayers()
          .getArray()
          .filter(layer =>
            [
              this.zdmDataDataSources.cabinet.datasource,
              this.zdmDataDataSources.street_lamp.datasource,
              this.zdmDataDataSources.power_station.datasource,
            ].includes(layer.get('data_source_name'))
          )
          .map(layer => {
            return { group: 'layers', id: layer.get('id') };
          }),
        ...snappingAdditionalLayers,
      ];
      this.initCableSnapping(layersToSnapIds);
      this.isModifySnappingActive = true;
    },
    toggleAddMultipartGeometry({ type, params }) {
      this.deleteDrawModifyInteractions();
      if (type === 'parcel') {
        this.attachListener('singleclick', async e => {
          const { coordinate } = e;
          try {
            const data = await this.getAssignedGeometry(coordinate, 'adding-parcel-multipart');
            this.$root.$emit('identification-action', false);
            if (!data?.geometry) return;
            const geometry = data.geometry.type.startsWith('Multi')
              ? flatten(data.geometry).features[0].geometry
              : data.geometry;
            params.callback({
              feature: new Feature({ geometry: new GeoJSON().readGeometry(geometry) }),
            });
          } catch (error) {
            console.error(`Multipart geometry adding error -> ${error}`);
            this.isModifyMultipartActive = false;
          }
        });
        this.attachCrosshairMoveHandler();
      } else {
        this.initDrawingSidebarGeometry({
          ...params,
          drawendCallback: params.callback,
          perserveLayer: true,
        });
        if (this.isSnappingConfigToolActive) this.initSnapping(this.getLayerById('drawingSidebarLayer').getSource());
      }
    },
    toggleRemoveMultipartGeometry({ geometryValue, styleFunction, selectCallback }) {
      this.deleteDrawModifyInteractions();
      if (this.isModificationInteractionActive) {
        this.deleteSidebarGeometry();
      }
      if (!geometryValue) return;
      this.isModifyMultipartActive = true;
      this.initDrawingLayer({ styleFunction });
      const geom = new GeoJSON().readGeometry(geometryValue, {
        featureProjection: defaultEpsg || 'EPSG:4326',
        dataProjection: (geometryValue.geometry || geometryValue)?.crs?.properties.name || defaultEpsg || 'EPSG:4326',
      });
      const simpleGeomName = this.getOlGeometrySimpleType(geometryValue.type);
      this.addDrawingLayerFeature(geom[`get${simpleGeomName}s`](), 'ol');
      const layerSource = this.getLayerById('drawingSidebarLayer').getSource();
      const initialFeatures = layerSource.getFeatures();
      this.attachMoveCursorHandler('pointer', layer => layer.get('id') === 'drawingSidebarLayer');
      if (initialFeatures.length === 1) {
        this.dettachCursorMoveHandler();
        selectCallback({});
        return;
      }
      this.attachListener(
        'click',
        e => {
          const featuresBeneathCursor = this.map.getFeaturesAtPixel(e.pixel, {
            layerFilter: layer => {
              return layer.get('id') === 'drawingSidebarLayer';
            },
          });
          if (!featuresBeneathCursor.length) return;
          unByKey(this.listenerKey);
          this.dettachCursorMoveHandler();
          const multi = new { MultiLineString, MultiPoint, MultiPolygon }[geometryValue.type]([]);
          layerSource.removeFeature(featuresBeneathCursor[0]);
          const newFeatures = layerSource.getFeatures();
          layerSource.clear();
          this.addDrawingLayerFeature(newFeatures, 'feature');
          newFeatures.forEach(ft =>
            multi[`append${this.getOlGeometrySimpleType(geometryValue.type)}`](ft.getGeometry())
          );
          selectCallback({
            newGeometry: new Feature({
              geometry: multi,
            }),
          });
        },
        {
          type: 'on',
          cursor: null,
        }
      );
    },
    initModifySidebarGeometry({
      geometryValue,
      geometryType,
      modifyendCallback,
      layerId,
      isInitSnapping,
      snappingAdditionalLayers = [],
      insertVertexCondition,
      styleFunction,
      additionalStyleFunction,
      condition,
    }) {
      this.deleteDrawModifyInteractions();
      if (this.isModificationInteractionActive) {
        this.deleteSidebarGeometry();
      }
      this.initDrawingLayer({ styleFunction, additionalStyleFunction });
      this.handleModifyInteractionAdding({
        modifyendCallback,
        layerId,
        geometryValue,
        geometryType,
        isInitSnapping,
        snappingAdditionalLayers,
        insertVertexCondition,
        condition,
      });
      if (geometryValue) {
        this.addDrawingLayerFeature(geometryValue);
      }
    },
    initDrawingSidebarGeometry({
      geometryType,
      geometryValue,
      perserveLayer = false,
      withEditing = false,
      drawendCallback,
      modifyendCallback,
      geometryFunction,
      marker = false,
      freehand = false,
      maxPoints,
      isDeactivateDrawingInteraction = true,
      condition,
      layerId,
      isInitSnapping = true,
      snappingAdditionalLayers = [],
      isSelection = false,
      activeLayer = null,
      insertVertexCondition,
      forceDefaultSnapping = false,
      styleFunction,
      additionalStyleFunction,
      drawType,
      finishCondition,
    }) {
      this.$root.$emit('deactivateAllTools');
      this.deleteDrawModifyInteractions();
      if (!perserveLayer) {
        if (this.isModificationInteractionActive) {
          this.deleteSidebarGeometry();
        }
        this.initDrawingLayer({ marker, isSelection, styleFunction, additionalStyleFunction });
      }
      this.handleDrawInteractionAdding({
        geometryType: this.getOlGeometryTypeFromLayerGeometry(geometryType),
        drawendCallback,
        geometryFunction,
        marker,
        condition,
        freehand,
        maxPoints,
        isDeactivateDrawingInteraction,
        layerId,
        isSelection,
        drawType,
        finishCondition,
        snappingAdditionalLayers,
        additionalStyleFunction,
      });
      if (isInitSnapping) {
        if (activeLayer === this.cableLayer) {
          if (!this.getInteractionByName('cableSnapping')) {
            this.handleCableSnappingAdding({ snappingAdditionalLayers });
          }
        } else if (activeLayer !== this.cableLayer) {
          if (!this.getInteractionByName('snappingInteraction')) {
            this.initModifySnapping({ activeLayer, snappingAdditionalLayers, forceDefaultSnapping });
          }
        }
      }
      if (withEditing) {
        this.handleModifyInteractionAdding({
          modifyendCallback: modifyendCallback || drawendCallback,
          layerId,
          isInitSnapping,
          snappingAdditionalLayers,
          activeLayer,
          insertVertexCondition,
          condition,
        });
      }
      if (geometryValue) {
        this.addDrawingLayerFeature(geometryValue);
      }
    },
    addDrawingLayerFeature(geometry, geometryFormat = 'geojson') {
      const layer = this.getLayerById('drawingSidebarLayer');
      for (const featureGeom of Array.isArray(geometry) ? geometry : [geometry]) {
        let feature;
        if (geometryFormat === 'geojson') {
          feature = new GeoJSON().readFeature(featureGeom, {
            featureProjection: defaultEpsg || 'EPSG:4326',
            dataProjection: (featureGeom.geometry || featureGeom).crs.properties.name || 'EPSG:4326',
          });
        } else if (geometryFormat === 'ol') {
          feature = new Feature({ geometry: featureGeom });
        } else if (geometryFormat === 'feature') {
          feature = featureGeom;
        }
        layer.getSource().addFeature(feature);
      }
    },
    setDrawingSidebarInteractionCoordinates(coordinates) {
      const interaction = this.getInteractionByName('drawingSidebarInteraction');
      if (interaction) {
        interaction.abortDrawing();
        if (coordinates?.length) {
          interaction.appendCoordinates(coordinates);
        }
      }
    },
    finishDrawingSidebarInteraction() {
      const interaction = this.getInteractionByName('drawingSidebarInteraction');
      if (interaction) {
        interaction.finishDrawing();
      }
    },
    deleteDrawingLayerFeature(featureId) {
      const layer = this.getLayerById('drawingSidebarLayer');
      const feature = layer.getSource().getFeatureById(featureId);
      if (feature) layer.getSource().removeFeature(feature);
    },
  },
  mounted() {
    this.registerSidebarGeometryListeners();
  },
};
