<template>
  <v-menu left offset-y max-width="450" :close-on-content-click="false" :attach="true">
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip bottom color="font">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon v-on="{ ...onMenu, ...onTooltip }" x-small>
            <v-icon :size="16" :color="$_colors.iconIdle">
              {{ '$mapArrow' }}
            </v-icon>
          </v-btn>
        </template>
        {{ $i18n.t('tooltips.shareMapView') }}
      </v-tooltip>
    </template>
    <v-card class="pa-3 text-left">
      <v-row no-gutters style="align-items: center">
        <v-col class="px-2">
          <a :href="generatedUrl" target="_blank" style="word-break: break-all" class="caption">{{ generatedUrl }}</a>
        </v-col>
        <v-col style="flex-grow: 0" class="ml-3">
          <v-tooltip v-model="copyTooltipVisible" bottom color="primary">
            <template v-slot:activator="{ attrs }">
              <v-btn color="primary" v-bind="attrs" @click="copyToClipboard" icon small>
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.t('tooltips.valueCopied') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters style="align-items: center">
        <v-col class="px-2">
          <italic-subheader translation-path="navbar.shareMapView" class="text-left" />
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';
import { get } from 'vuex-pathify';

export default {
  name: 'TheNavbarToolsPanelMapShareView',
  components: {
    ItalicSubheader,
  },
  data: () => ({
    copyTooltipVisible: false,
  }),
  computed: {
    mapCenter: get('map/center'),
    mapZoom: get('map/zoom'),
    currentProjectId: get('layers/project@id'),
    generatedUrl() {
      return `${import.meta.env.VUE_APP_HOST}/map?project=${this.currentProjectId}&X=${this.mapCenter?.[0]}&Y=${this.mapCenter?.[1]}&Z=${this.mapZoom}`;
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.generatedUrl);
      this.copyTooltipVisible = true;
      setTimeout(() => {
        this.copyTooltipVisible = false;
      }, 1500);
    },
  },
};
</script>
