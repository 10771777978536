<template>
  <sidebar-row
    v-bind="{ ...$props, ...$attrs }"
    class="feature-card-data-sidebar-row"
    :class="{ 'pr-5': !$attrs.equalPadding }"
    align-class="align-baseline"
  >
    <span class="d-flex align-baseline">
      <span class="flex-grow-1">
        <feature-card-data-row-input
          v-on="$listeners"
          v-bind="{ ...$props, ...$attrs }"
          :placeholder="$i18n.t('admin.noData')"
          v-model="computedValue"
          :isEditingOn="isEditingOn"
          :values.sync="computedValues"
        >
          <template v-for="(value, key) in $scopedSlots" #[key]="scope">
            <slot :name="key" v-bind="scope" />
          </template>
        </feature-card-data-row-input>
      </span>
      <slot :name="`row-input-${attribute}-append`" :changeValueTrigger="changeValueTrigger" />
    </span>
  </sidebar-row>
</template>

<script>
import SidebarRow from '@/components/SidebarRow';
import FeatureCardDataRowInput from '@/components/FeatureCardDataRowInput';

export default {
  name: 'FeatureCardDataRow',
  components: {
    SidebarRow,
    FeatureCardDataRowInput,
  },
  props: {
    isEditingOn: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    dataType: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Boolean, Date, Number, Array],
      default: null,
    },
    unit: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    changeValueTrigger(value) {
      this.computedValue = value;
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    computedValues: {
      get() {
        return this.values;
      },
      set(nV) {
        this.$emit('update:values', nV);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
