<template>
  <v-app id="app" class="fill-height">
    <v-main class="fill-height">
      <router-view />
      <the-snackbar />
      <main-dialog
        v-if="isMainDialogLoaded"
        :is-visible.sync="isMainDialogVisible"
        :title="title"
        :body="body"
        :agree-text="buttonText"
        @agree="agreeCallback"
      >
      </main-dialog>
    </v-main>
  </v-app>
</template>

<script>
import 'vuetify/dist/vuetify.min.css';
import { call, get } from 'vuex-pathify';
import sockets from '@/mixins/sockets/sockets';
import celeryTasks from '@/mixins/celeryTasks';
import Vue from 'vue';

import TheSnackbar from '@/components/TheSnackbar';
import { forEach, isBoolean, isNumber, isString } from 'lodash';

Vue.prototype.$globalHub = new Vue();

export default {
  name: 'App',
  mixins: [sockets, celeryTasks],
  components: {
    TheSnackbar,
    MainDialog: () => import('@/components/MainDialog'),
  },
  data: () => ({
    isMainDialogLoaded: false,
    isMainDialogVisible: false,
    title: undefined,
    body: undefined,
    buttonText: undefined,
    agreeCallback: undefined,
  }),
  computed: {
    gtagEventToSend: get('gtag/active'),
  },
  watch: {
    gtagEventToSend(/** @type {?EventDef} */ event) {
      if (event) this.sendGtagEvent(event);
      this.sendingGtagEvent(undefined);
    },
  },
  methods: {
    sendingGtagEvent: call('gtag/sendingNextEvent'),
    sendGtagEvent(/** @type {EventDef} */ event) {
      if (!this.$gtag) return;
      const toString = params => (params ? JSON.stringify(params) : undefined);
      const copyParam = param => (isString(param) || isNumber(param) || isBoolean(param) ? param : undefined);
      function copyParams(source, prefix = '') {
        let target = {};
        forEach(source || {}, (value, key) => (target[`${prefix}${key.trim()}`] = copyParam(value)));
        return target;
      }
      this.$gtag.event(event.action, {
        ...copyParams(event.extra_params),
        // nadpisanie wrazie pokrywających się
        event_category: event.category,
        event_label: event.label ? event.label : toString(event.params),
        value: event.value,
        ...copyParams(event.params, 'param_'),
      });
    },
  },
  mounted() {
    this.initSocket();
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/_print.scss';

html,
body {
  height: 100% !important;
  overflow-y: hidden !important;
}
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: map-get($map: $colors, $key: 'font');
  .persist-menu-width {
    max-width: 0 !important;
  }
  .menu-max-width-content {
    .v-list-item__content {
      overflow: visible !important;
    }
    .v-list-item {
      min-width: 0 !important;
    }
  }
  * {
    scrollbar-color: #8f8f8f white;
    scrollbar-width: thin;
  }
  .tooltip_multiline {
    white-space: pre-wrap !important;
    text-align: left !important;
  }
}
.treeview-type-select ::placeholder {
  color: white !important;
}

/** Simulating disabled state on custom icons */
.v-btn--icon {
  &.v-btn--disabled svg {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}
.hover-list-element:hover {
  background-color: rgba(188, 188, 188, 0.2) !important;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #8f8f8f;
  border: 0px none #ffffff;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #787878;
}
::-webkit-scrollbar-thumb:active {
  background: #6e6e6e;
}
::-webkit-scrollbar-track {
  background: #ededed;
  border: 0px none #ffffff;
  border-radius: 3px;
}
::-webkit-scrollbar-track:hover {
  background: #ededed;
}
::-webkit-scrollbar-track:active {
  background: #ededed;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.project-data-input .v-select-list {
  padding-bottom: 0 !important;
}
.group-input__menu .v-subheader {
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
}
.group-input__menu .v-list-item__title {
  width: 100% !important;
  padding: 0 1rem;
}
.group-input__menu .v-list-item {
  padding: 0px !important;
}
.v-color-picker__swatches div {
  padding: 0 !important;
}
@for $i from 0 through 4 {
  .empty-column-#{$i} {
    .v-color-picker__swatches {
      .v-color-picker__swatch:nth-child(#{$i + 1}) {
        margin-top: 22px;
        .v-color-picker__color:nth-child(1) {
          display: none !important;
        }
      }
    }
  }
}
</style>
