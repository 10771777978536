<template>
  <v-card class="fill-height" style="width: 100%" elevation="0">
    <v-row no-gutters class="fill-height">
      <v-col>
        <v-tabs
          grow
          v-model="currentTab"
          ref="tabs"
          class="d-flex flex-column"
          :style="`height: ${
            isStickyButtonsVisible && stickyButtons.length > 0 && currentTab === 1 ? 'calc(100% - 76px)' : '100%'
          }`"
          hide-slider
          color="font"
          :height="isCardInfoPrint ? '0' : '39px'"
        >
          <v-tab
            :disabled="isTabDisabled(tab.value)"
            v-for="tab in computedTabs"
            :key="tab.value"
            :style="{ minHeight: isCardInfoPrint ? '0' : '39px' }"
            class="text-subtitle-1 font-weight-medium"
            :class="[
              borderClass,
              { 'print-sidebar_title': isQuickPrint },
              { 'no-print': (isQuickPrint && currentTab !== tab.tab) || isCardInfoPrint },
            ]"
            :ripple="false"
          >
            <v-row no-gutters>
              <v-col style="position: relative">
                <v-btn
                  class="x-button"
                  :class="{ 'no-print': isQuickPrint }"
                  v-if="isCloseButtonVisible(tab)"
                  x-small
                  icon
                  style="position: absolute; bottom: 15px; right: -15px; opacity: 0.7"
                  @click.stop.prevent="goToLegend"
                  color="rgb(196, 196, 196)"
                >
                  <v-icon color="font" size="16"> mdi-close </v-icon>
                </v-btn>
                {{ tab.text }}
              </v-col>
            </v-row>
          </v-tab>
          <v-tabs-items v-model="currentTab" class="fill-height sidebar-content-printing" style="display: contents">
            <v-tab-item>
              <the-legend v-if="isFetched"></the-legend>
            </v-tab-item>
            <v-tab-item class="pb-16">
              <router-view name="sidebar" v-if="isFetched"></router-view>
              <main-loader v-else></main-loader>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <sidebar-sticky-buttons v-if="isFetched && currentTab === 1" />
      </v-col>
    </v-row>
    <main-dialog
      v-if="isAcceptStopEditingDialogLoaded"
      :is-visible="unsavedChangesNext ? true : false"
      @update:isVisible="unsavedChangesNext = undefined"
      :title="$i18n.t('dialog.title.unsavedChanges')"
      :body="$i18n.t('dialog.body.unsavedChangesConfirmation')"
      agree-text="dialog.agreeUnsavedChanged"
      @agree="acceptStopEditing"
    />
  </v-card>
</template>

<script>
import { get, sync } from 'vuex-pathify';

import MainLoader from '@/components/MainLoader';
import TheLegend from '@/components/TheLegend';
import SidebarStickyButtons from '@/components/SidebarStickyButtons';

export default {
  name: 'TheSidebar',
  components: {
    MainLoader,
    TheLegend,
    SidebarStickyButtons,
    MainDialog: () => import('@/components/MainDialog'),
  },
  data: () => ({
    backToTableRoutesName: [
      'layerFeature',
      'fireSimulationLayer',
      'buffer',
      'circularSectorSelection',
      'antennaSignalDetermination',
    ],
    closeClicked: false,
    isAcceptStopEditingDialogLoaded: false,
  }),
  computed: {
    currentTab: sync('sidebar/currentTab'),
    isFetched: get('sidebar/isFetched'),
    isStickyButtonsVisible: sync('sidebar/isStickyButtonsVisible'),
    stickyButtons: sync('sidebar/stickyButtons'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    isCardInfoPrint: get('tools/toolStatus@isCardInfoPrintActive'),
    unsavedChangesNext: sync('sidebar/unsavedChangesNext'),
    hasUnsavedChanges: sync('sidebar/hasUnsavedChanges'),
    routeName() {
      return this.$route.name;
    },
    routePath() {
      return this.$route.path;
    },
    isSecondTabVisible() {
      const { matched, name } = this.$route;
      return !!matched.find(route => route.name === name)?.components.sidebar;
    },
    computedTabs() {
      return [
        { text: this.$i18n.t('sidebar.legend'), value: 'legend', tab: 0 },
        { text: this.$i18n.t('sidebar.info'), value: 'info', tab: 1 },
      ];
    },
    project: get('layers/project'),
    borderClass() {
      return `border${this.currentTab === 0 ? 'Left' : 'Right'}`;
    },
  },
  watch: {
    isSecondTabVisible: {
      immediate: true,
      deep: true,
      handler(nV) {
        if (nV) {
          if (this.$route.params.stayOnLegend) {
            this.currentTab = 0;
          } else {
            this.currentTab = 1;
          }
        } else {
          this.currentTab = 0;
        }
      },
    },
    unsavedChangesNext: {
      deep: true,
      handler(nV) {
        if (nV) this.isAcceptStopEditingDialogLoaded = true;
      },
    },
  },
  methods: {
    acceptStopEditing() {
      this.unsavedChangesNext();
      this.$nextTick(() => {
        this.unsavedChangesNext = undefined;
      });
    },
    isCloseButtonVisible(tab) {
      return tab.value === 'info' && this.currentTab === 1;
    },
    async goToLegend() {
      const cancelButton = this.stickyButtons.find(el => el.cancel && !el.preventGoToLegendCancel);
      if (this.backToTableRoutesName.includes(this.routeName)) {
        if (this.routeName === 'layerFeature' && this.hasUnsavedChanges) {
          this.$root.$emit('openStopEditingDialog');
          return;
        }
        cancelButton
          ? this.$root.$emit(cancelButton.action)
          : await this.$router.push({ name: 'layerTable', query: this.$route.query });
        this.currentTab = 0;
        this.isStickyButtonsVisible = false;
        this.stickyButtons = [];
      } else {
        cancelButton
          ? this.$root.$emit(cancelButton.action, true)
          : await this.$router.push({ name: 'map', query: this.$route.query });
        this.currentTab = 0;
        this.isStickyButtonsVisible = false;
        this.stickyButtons = [];
      }
    },
    isTabDisabled(tab) {
      return tab === 'info' && !this.isSecondTabVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-window-item {
  height: inherit;
}

.v-window-item .row {
  display: block !important;
}

.v-tab {
  background-color: rgba(207, 207, 207, 0.25);
  @include border(bottom);
  text-transform: unset !important;
}
::v-deep {
  .v-slide-group {
    z-index: 3;
    position: sticky;
    top: 0;
  }
  .v-window__container--is-active {
    .floating-title {
      top: 0 !important;
    }
  }
  .v-tab--active {
    .x-button:hover {
      background-color: rgba(207, 207, 207, 0.25);
    }
    background-color: white;
    border: none;
  }
  .v-tab--active::before {
    background-color: white;
    border: none;
  }
}

.borderRight {
  @include border(left);
}

.borderLeft {
  @include border(right);
}
</style>
