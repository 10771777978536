<template>
  <v-autocomplete
    ref="data-input-v-autocomplete"
    v-on="$listeners"
    v-bind="{ ...$props, ...$attrs }"
    :height="fixedHeight ? 16 : null"
    :no-data-text="$i18n.t('admin.noData')"
    :class="{ 'v-select--wrapped': wrapped }"
    :menu-props="menuProps"
  >
    <template v-for="(value, key) in $scopedSlots" #[key]="scope">
      <slot :name="key" v-bind="scope" />
    </template>
    <template #message="{ message }">
      <italic-subheader :translation-path="message"> </italic-subheader>
    </template>
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template #append-item>
      <slot name="append-item" />
    </template>
    <template #no-data>
      <slot name="no-data" />
    </template>
  </v-autocomplete>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'DataInputAutocomplete',
  components: {
    ItalicSubheader,
  },
  model: {
    event: 'change',
  },
  props: {
    fixedHeight: {
      type: Boolean,
      default: true,
    },
    value: {
      default: null,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
    menuProps: {
      type: Object,
      default: () => ({
        offsetY: true,
      }),
    },
  },
  methods: {
    openMenu() {
      const inputRef = this.$refs['data-input-v-autocomplete'];
      if (!inputRef) return;
      inputRef.activateMenu();
      inputRef.isMenuActive = inputRef.isFocused = true;
    },
    closeMenu() {
      const inputRef = this.$refs['data-input-v-autocomplete'];
      if (!inputRef) return;
      inputRef.isMenuActive = inputRef.isFocused = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select--wrapped :deep(.v-chip) {
  margin: 0 !important;
}
.v-select--wrapped :deep(.v-input__slot) {
  height: auto !important;
}
.v-select--wrapped :deep(.v-select__selections) {
  flex-wrap: wrap !important;
  gap: 0.3rem 0.4rem;
}
::v-deep {
  .v-list-item__title {
    @include selectListElement();
  }
  .theme--light.v-icon.v-icon.v-icon--disabled {
    display: none;
  }
  .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
