<template>
  <div>
    <v-menu min-width="250px" offset-y attach>
      <template #activator="{ on, attrs }">
        <div class="d-flex align-items" style="gap: 0.3rem">
          <v-btn v-on="on" small class="elevation-0">
            <div class="d-flex align-center" style="gap: 0.5rem">
              <div class="d-flex align-center" style="gap: 0.3rem">
                <v-icon color="fontlight" :style="{ color: $_colors.fontlight }" small>{{ icon }}</v-icon>
                <span
                  style="font-size: 10px; letter-spacing: 0.03333rem"
                  class="font-weight-medium"
                  :style="{ color: $_colors.fontlight }"
                >
                  {{ spatialBookmarksVerboseName }}
                </span>
              </div>
              <v-icon
                small
                color="fontlight"
                :style="attrs['aria-expanded'] === 'true' ? 'transform: rotate(180deg)' : ''"
                >mdi-menu-down</v-icon
              >
            </div>
          </v-btn>
        </div>
      </template>
      <v-card max-height="300px">
        <v-list>
          <v-list-item dense v-for="(item, idx) in userSpatialBookmarks" :key="idx" @click="setSpatialBookmark(item)">
            <v-list-item-title class="text-left font-weight-regular text-body-2">{{ item.name }}</v-list-item-title>
            <v-list-item-action>
              <v-tooltip color="font" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small @click="$event => openDeleteSpatialBookmarkDialog($event, item)">
                    mdi-close
                  </v-icon>
                </template>
                <span>{{ $i18n.t('navbar.deleteBookmark') }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="openAddSpatialBookmarkDialog" class="add-project-wrapper elevation-4 mt-2">
            <v-list-item-title
              class="py-0 d-flex flex-row align-center text-left mt-3 mb-1"
              style="align-self: self-start; gap: 0.5rem"
            >
              <div style="width: 16px; min-width: 16px" class="d-flex justify-center align-center">
                <v-icon size="16px" color="success"> mdi-plus-circle </v-icon>
              </div>
              <span class="text-truncate text-body-2 py-0 mr-4" :style="{ color: $_colors.success }">{{
                $i18n.t('navbar.addBookmark')
              }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <main-input-dialog
      v-if="isAddSpatialBookmarkLoaded"
      :is-visible.sync="isAddSpatialBookmarkVisible"
      :inputs="addSpatialBookmarkInput"
      :title-translation="$i18n.t('dialog.addSpatialBookmark')"
      agree-text="dialog.agreeAdd"
      :loading="isLoading"
      @agree="addSpatialBookmarkHandler"
    />
    <main-dialog
      v-if="isDeleteSpatialBookmarkLoaded"
      :is-visible.sync="isDeleteSpatialBookmarkVisible"
      :title="$i18n.t('dialog.title.deleteSpatialBookmark')"
      :body="
        $i18n.t('dialog.body.deleteSpatialBookmarkConfirmation', {
          name: bookmarkToDelete ? bookmarkToDelete.name : '',
        })
      "
      agree-text="dialog.agreeDelete"
      :loading="isLoading"
      @agree="deleteSpatialBookmarkHandler"
    />
  </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';

export default {
  name: 'TheNavbarToolsPanelSpatialBookmarks',
  components: {
    MainDialog: () => import('@/components/MainDialog'),
    MainInputDialog: () => import('@/components/MainInputDialog'),
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isAddSpatialBookmarkLoaded: false,
    isAddSpatialBookmarkVisible: false,
    isDeleteSpatialBookmarkLoaded: false,
    isDeleteSpatialBookmarkVisible: false,
    isLoading: false,
    bookmarkToDelete: null,
  }),
  computed: {
    zoom: sync('map/zoom'),
    coordinates: sync('map/center'),
    spatialBookmarks: get('map/spatialBookmarks'),
    userId: get('users/currentUser@id'),
    spatialBookmarksVerboseName: get('admin/additionalModules@SPATIAL_BOOKMARKS.verbose_name'),
    userSpatialBookmarks() {
      return this.spatialBookmarks.filter(bookmark => bookmark.owner === this.userId);
    },
    addSpatialBookmarkInput() {
      return [
        {
          name: 'spatialBookmarkName',
          translationPath: 'dialog.body.bookmarkName',
          dataType: { name: 'text' },
          rules: [v => !!v || v == '0' || 'rules.required'],
          cols: '12',
          leftCol: 5,
          rightCol: 6,
          spacer: true,
          value: '',
        },
      ];
    },
  },
  mounted() {
    this.getSpatialBookmarks();
  },
  methods: {
    getSpatialBookmarks: call('map/getSpatialBookmarks'),
    addSpatialBookmark: call('map/addSpatialBookmark'),
    deleteSpatialBookmark: call('map/deleteSpatialBookmark'),
    setSpatialBookmark(bookmark) {
      this.zoom = bookmark.zoom;
      this.coordinates = bookmark.map_center.coordinates;
    },
    async addSpatialBookmarkHandler() {
      try {
        this.isLoading = true;
        const payload = {
          name: this.addSpatialBookmarkInput[0].value,
          zoom: this.zoom,
          map_center: {
            coordinates: this.coordinates,
            crs: {
              properties: {
                name: this.$_config.defaultEpsg,
              },
              type: 'name',
            },
            type: 'Point',
          },
        };
        await this.addSpatialBookmark(payload);
        this.isAddSpatialBookmarkLoaded = false;
        this.isAddSpatialBookmarkVisible = false;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteSpatialBookmarkHandler() {
      try {
        this.isLoading = true;
        await this.deleteSpatialBookmark(this.bookmarkToDelete.id);
        this.bookmarkToDelete = null;
        this.isDeleteSpatialBookmarkLoaded = false;
        this.isDeleteSpatialBookmarkVisible = false;
      } finally {
        this.isLoading = false;
      }
    },
    openAddSpatialBookmarkDialog() {
      this.addSpatialBookmarkInput[0].value = '';
      this.isAddSpatialBookmarkLoaded = true;
      this.isAddSpatialBookmarkVisible = true;
    },
    openDeleteSpatialBookmarkDialog(event, bookmark) {
      event.stopPropagation();
      this.bookmarkToDelete = bookmark;
      this.isDeleteSpatialBookmarkLoaded = true;
      this.isDeleteSpatialBookmarkVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .activeicon * {
  color: map-get($colors, 'link') !important;
}
:deep(.v-list) {
  padding-bottom: 0 !important;
}
.add-project-wrapper {
  @include border(top, 1px);
  position: sticky !important;
  bottom: 0;
  background-color: white;
  z-index: 1;
}
.background {
  @include activeToolButtonStyle();
}
.divider {
  position: relative;
  margin-right: 18px;
  &::after {
    content: '';
    position: absolute;
    @include border(right);
    height: 100%;
    top: 0;
    right: -9px;
  }
}
.v-btn {
  text-transform: none;
  &::before {
    background-color: transparent;
  }
}
.icon-delete {
  cursor: pointer;
  &:hover {
    color: black;
  }
}
</style>
