<template>
  <v-menu
    v-model="isMenuVisible"
    top
    nudge-bottom="105"
    nudge-left="16"
    :close-on-content-click="false"
    :disabled="disabled"
    min-width="300px"
  >
    <template #activator="{ on }">
      <v-row no-gutters style="height: 51px" class="flex-column justify-end">
        <v-col cols="auto">
          <div :style="swatchStyle" v-on="on" class="included" />
          <italic-subheader :translation-path="hint" class="italic--text" style="min-height: 16px"> </italic-subheader>
        </v-col>
      </v-row>
    </template>
    <input-card
      @save="save"
      @cancel="cancel"
      :is-save-disabled="isSaveDisabled"
      v-click-outside="{
        handler: onClickOutside,
        include,
        closeConditional,
      }"
    >
      <v-color-picker v-model="computedValue" v-bind="pickerBind" />
    </input-card>
  </v-menu>
</template>

<script>
import colorPickerSwatches from '@/mixins/colorPickerSwatches';
import inputCard from '@/mixins/inputCard';

import ItalicSubheader from '@/components/ItalicSubheader';
import InputCard from '@/components/InputCard';

export default {
  name: 'DataInputColor',
  inheritAttrs: false,
  mixins: [colorPickerSwatches, inputCard],
  components: {
    ItalicSubheader,
    InputCard,
  },
  props: {
    value: {
      type: String,
      default: '#000000',
    },
    hint: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => ({
    isMenuVisible: false,
  }),
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    swatchStyle() {
      const { computedValue, isMenuVisible } = this;
      return {
        backgroundColor: this.disabled ? '#8A8A8A' : computedValue,
        cursor: this.disabled ? 'default' : 'pointer',
        height: '25px',
        width: '25px',
        borderRadius: isMenuVisible ? '50%' : '4px',
        border: '1px solid lightgrey',
        transition: 'border-radius 200ms ease-in-out',
      };
    },
  },
  methods: {
    getValue() {
      return this.computedValue;
    },
    setValue() {
      this.addSwatch(this.computedValue);
      this.$emit('input', this.computedValue);
    },
  },
  watch: {
    computedValue(nV) {
      if (!nV) {
        this.computedValue = '#000000';
      } else if (typeof nV === 'string' && nV.length > 7 && nV[0] === '#') {
        this.computedValue = nV.substr(0, 7);
      } else if (typeof nV === 'object') {
        this.computedValue = nV.hex || '#000000';
      }
    },
  },
  mounted() {
    if (!this.computedValue) {
      this.computedValue = '#000000';
    }
  },
};
</script>

<style lang="scss" scoped></style>
