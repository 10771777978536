<template>
  <v-row
    no-gutters
    :class="[equalPadding ? 'pr-0' : paddingRight, 'text-break']"
    style="min-width: 0"
    v-on="$listeners"
  >
    <v-col>
      <slot name="sectionTitle"></slot>
      <v-row no-gutters :class="[alignClass, flexWrap, rowClass]">
        <v-col cols="1" v-if="computedHasIconColumn" style="min-width: 40px" :class="[rowClass]">
          <slot name="actionIcon">
            <v-row no-gutters class="justify-center">
              <v-col cols="auto">
                <v-icon :color="iconColor || $_colors.iconIdle" v-if="icon" v-on="$listeners">
                  {{ icon }}
                </v-icon>
              </v-col>
            </v-row>
          </slot>
        </v-col>
        <v-col
          style="min-width: 0"
          :cols="slotCols"
          :class="{
            'border-top': borderTop,
            'border-bottom': borderBottom,
          }"
        >
          <slot> </slot>
        </v-col>
        <v-col
          v-if="equalPadding && computedHasIconColumn"
          cols="1"
          style="min-width: 40px"
          :class="[rowClass]"
        ></v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SidebarRow',
  props: {
    hasIconColumn: {
      type: Boolean,
      default: true,
    },
    dynamicIconColumn: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
    borderTop: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    alignClass: {
      type: String,
      default: 'align-baseline',
    },
    slotCols: {
      type: String,
      default: '',
    },
    rowClass: {
      type: String,
      default: '',
    },
    equalPadding: {
      type: Boolean,
      default: false,
    },
    flexWrap: {
      type: String,
      default: 'flex-nowrap',
    },
    paddingRight: {
      type: String,
      default: 'pr-5',
    },
  },
  computed: {
    computedHasIconColumn() {
      return this.dynamicIconColumn ? !!this.icon : this.hasIconColumn;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-top {
  @include border(top);
}
.border-bottom {
  @include border(bottom);
}
</style>
